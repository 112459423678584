import { EachInput } from "../auth.styles";

// Input container for the login
const InputContainer = ({label, value, name, onChange, placeholder}) => {
    return (
        <EachInput>
            <label>{label}</label>
            <input type="text" placeholder={placeholder} value={value} name={name} onChange={onChange} required />
        </EachInput>
      );
}
 
export default InputContainer;