import { useState, useEffect } from "react";
import { httpGetSingleCourse } from "../Hooks/courses.hooks";
import { useParams } from "react-router-dom";
import { BreadcrumbContainer, SingleCourseContainer, SingleCardContainer, SingleCourseImage, SingleCourseInfo, SingleTop, SingleBottom, SingleButton } from "./EachCourse.styles";
import BreadCrumbs from "../Components/Breadcrumbs/breadcrumbs.component";
import BuyButton from "../BuyCourse/BuyButton/buyButton.component";

// Showing a single course to purchase
const SingleCourse = () => {
    const {id} = useParams();
    const [course, setCourse] = useState();
    const [err, setErr]= useState('');

    useEffect(() => {
        const FetchCourse = async() => {
            const data = await httpGetSingleCourse(id);
            if(!data) setErr('Error getting the course');
            setCourse(data);

        }
        FetchCourse();
    }, [id]);


    return ( 
       <SingleCourseContainer>
        <BreadcrumbContainer>
        <BreadCrumbs one="courses" oneLink='/online' two={course?.courseName}/>
        </BreadcrumbContainer>
        <SingleCardContainer>
        <SingleTop>
        <SingleCourseImage>
        {err && <p>{err}</p>}
        <img src={course?.image} alt="course"/>
        </SingleCourseImage>
        <SingleCourseInfo>
            <div className="courseTop">
            <h1>{course?.courseName}</h1>
            <h2>${course?.price}</h2>
            </div>
            <div className="courseDescription">
            <h4>Course Details</h4>
            <p>{course?.description}</p>
            </div>
            <div className="courseMiddle">
            <div className="industries">
                <h5>Industries:</h5>
                <ul style={{paddingLeft: '2rem', listStyleType: 'square'}}>
                {course?.tags?.map((t, x) => <li key={x}>{t}</li>)}
                </ul>
           </div>
           <h5>Expires In: <span style={{fontWeight: 'normal'}} >{course?.expiryDate} years</span></h5>
            </div>
            <SingleButton>
                <BuyButton />
        </SingleButton>
        </SingleCourseInfo>
        </SingleTop>
        <SingleBottom>
        </SingleBottom>
        </SingleCardContainer>
       </SingleCourseContainer>
     );
}
 
export default SingleCourse;