import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";
import Loader from "../../../Components/Loader/loader.component";
import { useState } from "react";
import { activateUser } from "../../../Utils/Users/editUsers/activateUser";

// Activates a user
const ActivateUserButton = ({setSuccessState, setIsOpen, id}) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = async(e) => {
        e.preventDefault();
        await activateUser(id, setSuccessState, setIsOpen, setLoading)
    }

    return ( 
        <>
        {loading ? <Loader /> :
        <SecondaryButton functionName={handleDelete} span="Activate User" />}
        </>
     );
}
 
export default ActivateUserButton;