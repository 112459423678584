import { MainContainer, MainSection } from "../MainRoute.styles";
import Images from '../../Visuals/Images/large.jpg';
const ClassroomTraining = () => {
    return (
      <MainContainer>
      <h1>Classroom Training</h1>
      <MainSection> 
        {/* <img src={Images} alt="altsdfds" /> */}
          <p> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia ea ipsum nulla rem, dolore inventore. Saepe nesciunt adipisci repudiandae. Quisquam temporibus recusandae ipsum tenetur sapiente eveniet aut expedita praesentium dolor.</p>
      </MainSection>
      </MainContainer>
      );
}
 
export default ClassroomTraining;