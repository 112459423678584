import EachComponent from "../../../Components/Entity/each.component";
import SearchCompanies from "./searchCompanies.component";
import { useState, useEffect } from "react";
import useCompanies from '../../../Utils/Companies/allCompanies/useCompanies';

// Results for the companies tab on admin page
const CompaniesTab = () => {
  const { companies, successState} = useCompanies();
  const [filtered, setFiltered] = useState([]);
  const [err, setErr] = useState('');
  useEffect(() => {
    setFiltered(companies);
  }, [companies]);
  

  return (
    <section>
      <h2>Companies:</h2>
      <SearchCompanies companies={companies} setFiltered={setFiltered} setErr={setErr}/>
      {err && <p style={{color: 'red'}}>{err}</p>}
      {successState && <p style={{color: 'green'}}>{successState}</p>}
      {filtered?.map((c) => {
        return (<EachComponent key={c?._id} name={c?.companyName} route={`/admin/edit-company/${c?._id}`} /> );
      })}
    </section>
  );
};

export default CompaniesTab;
