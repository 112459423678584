import { useState } from "react";
import Loader from "../../../Components/Loader/loader.component";
import {deleteUser} from '../../../Utils/Users/editUsers/deleteUser';

// Deletes a user
const DeleteUserButton = ({setSuccessState, setIsOpen, id}) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = async(e) => {
        e.preventDefault();
        await deleteUser(id, setSuccessState, setIsOpen, setLoading);
    }

    return ( 
        <>
        {loading ? <Loader /> :
         <button className="destructiveButton" onClick={handleDelete}><span>Delete User</span></button>
         }
       </>
     );
}
 
export default DeleteUserButton;