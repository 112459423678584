import Loader from "../../../Components/Loader/loader.component";
import { useState } from "react";
import DestructiveButton from "../../../Components/Buttons/destructiveButton.component";
import PropTypes from 'prop-types';
import {deleteCourse} from "../../../Utils/Courses/editCourse/deleteCourse";

// Deletes a course
const DeleteCourseButton = ({setSuccessState, setIsOpen, id}) => {
    const [loading, setLoading] = useState(false);

    const handleDeleteSubmit = async(e) => {
        e.preventDefault();
        await deleteCourse(id, setIsOpen, setLoading, setSuccessState)
    }

    return ( 
        <>
        {loading ? <Loader /> :
        <DestructiveButton functionName={handleDeleteSubmit} span="Delete Course" />
         }
        </>
     );
}

DeleteCourseButton.propTypes = {
    id: PropTypes.string.isRequired,
    setSuccessState: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
  };
  
 
export default DeleteCourseButton;