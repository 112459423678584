import { Outlet } from "react-router-dom";

const UserBase = () => {
    return (  
        <>
        <Outlet />
        </>
    );
}
 
export default UserBase;