import { useState } from "react";

// Main Frame for the company home
const CompanyHome = () => {


    return (<>
    <h2>Company Home</h2>
    </>  );
}
 
export default CompanyHome;