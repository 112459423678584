
// Function for deleting a quesiton from the quiz
const DeleteQuestion = ({questions, setQuestions, quest}) => {
    const clickedDelete = (e) => {
        e.preventDefault();
        let filtered = questions.filter(question => question.id !== quest.id);
        setQuestions(filtered);
    }
    return (  
        <button className="destructiveButton" onClick={clickedDelete}><span>Delete Question</span> </button>
    );
}
 
export default DeleteQuestion;