import { useState } from "react";
import Loader from "../../../Components/Loader/loader.component";
import DestructiveButton from "../../../Components/Buttons/destructiveButton.component";
import PropTypes from 'prop-types';
import { deleteCompany } from "../../../Utils/Companies/editCompany/deleteCompany";

// Deletes a company
const DeleteCompanyButton = ({ id, setSuccessState, setIsOpen }) => {
  const [loading, setLoading] = useState(false);

  const deleteCompanySubmit = async (e) => {
    e.preventDefault();
    await deleteCompany(id, setSuccessState, setIsOpen, setLoading);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <DestructiveButton
          functionName={deleteCompanySubmit}
          span="Delete Company"
        />
      )}
    </>
  );
};

DeleteCompanyButton.propTypes = {
  id: PropTypes.string.isRequired,
  setSuccessState: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default DeleteCompanyButton;
