import Auth from '../Helpers/auth';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = Auth.getToken();

// GET: Fetches a company - protected route
async function httpGetCompany(id){
    try{
       let company = await fetch(`${API_URL}/companies/${id}`, {
        headers: {
          'Authorization': token ? `Bearer ${token}` : ''
        }
       });
        return company.json();
    } catch(err){
        return err;
    }
}
// GET: Fetches the companys managers
async function httpGetCompanyManagers(id){
    try{
        let managers = await fetch(`${API_URL}/companies/managers/${id}`, {
            headers: {
                'Authorization': token ? `Bearer ${token}` : ''
            }
        });
        return managers.json();
    } catch(err){
        return err;
    }
}

// GET: Fetches the company's employees
async function httpGetCompanyEmployees(id){
    try{
        let employees = await fetch(`${API_URL}/companies/employees/${id}`, {
            headers: {
                'Authorization': token ? `Bearer ${token}` : ''
            }
        });
        return employees.json();
    } catch(err){
        return err;
    }
}
// POST: Creates a Manager or User for a company - protected route
async function httpCreateManagerOrUser(user, id){  
    return await fetch(`${API_URL}/companies/create-manager-or-user/${id}`, {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
            'Authorization': token ? `Bearer ${token}` : ''
        },
        body: JSON.stringify(user)
        })
} 

// PUT: Disables a company - protected route
async function httpDisableCompany(id){
    return await fetch(`${API_URL}/companies/disable-company/${id}`, {
        method: 'put',
        headers: {
          "Content-Type": "application/json",
          'Authorization': token ? `Bearer ${token}` : ''
        },
      })
}

// PUT: Activates a company - protected route
async function httpActivateCompany(id){
    return await fetch(`${API_URL}/companies/activate-company/${id}`, {
        method: 'put',
        headers: {
          "Content-Type": "application/json",
          'Authorization': token ? `Bearer ${token}` : ''
        },
      })
}

export {
   httpGetCompany,
   httpGetCompanyEmployees,
   httpGetCompanyManagers,
   httpCreateManagerOrUser,
   httpActivateCompany,
   httpDisableCompany
}