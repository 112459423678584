
const PdfInput = ({pdf, setNewPdf}) => {
    return (  
        <>
        <div style={{display: 'flex', justifyContent: 'space-between', marginBlock: '1rem', paddingBottom: '1rem', borderBottom: '1px solid #e6e6e6'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
        <label>Presentation Slideshow <span className="detail">* pdf's only</span></label>
        <input type="file" accept="application/pdf" onChange={(e) => setNewPdf(e.target.files[0])} />
        </div>
        <div style={{width: '50%'}}>
        <iframe src={pdf} title="Presentation Slideshow" width="100%" height="300px"></iframe>
        </div>
        </div>
        </>
    );
}
 
export default PdfInput;
<>

</>