import styled from 'styled-components';

export const FooterDiv = styled.footer`
    background-color: black;
    //position: absolute;
    padding-block: 1rem;  
    width: 100%;
    margin-top: auto;
    overflow-x: hidden;
    z-index: -2;
`;

export const OuterDiv = styled.div`
    /* border: 1px solid orange; */
    display: flex;
    gap: 1rem;
    @media screen and (width <= 1100px){
    flex-direction: column;
    gap: 0;
    }
`;

export const LeftFooter = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding-inline: 1rem;
    img{
        height: 100px;
         width: 180px;
     }
     @media screen and (width <= 800px){
        img{
        height: 70px;
         width: 140px;
        }
        p, span{
            font-size: 0.8rem;
        }
     }
`;

