import { useState } from "react";
import LoginComponent from "./login.component";
import SignUpComponent from "./signup.component";

// Switches the component to login or signup 
const AuthComponent = () => {
    const [page, setPage]=useState(true);
    return (
    <main>
        {page ? 
         <LoginComponent setPage={setPage}/>
            : 
         <SignUpComponent setPage={setPage} />
        }
    </main>  
    
);
}
 
export default AuthComponent;