import { MainContainer, MainSection } from "../MainRoute.styles";

const Insurance = () => {
    return (  
        <MainContainer>
        <h1>Insurance</h1>
        <MainSection> 
            <p> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Officia ea ipsum nulla rem, dolore inventore. Saepe nesciunt adipisci repudiandae. Quisquam temporibus recusandae ipsum tenetur sapiente eveniet aut expedita praesentium dolor.</p>
        </MainSection>
        </MainContainer>
    );
}
 
export default Insurance;