export const theme = {
    colors: {
      white: '#FEFEFE',
      offWhite: '#F3F3F3',
      grey: '#e6e6e6',
      grey1: '#D1CECE',
      grey2: '#B3B0B0',
      grey3: '#757575',
      grey4: '#515151',
      grey5: '#343434',
      grey6: '#262626',
      grey7: '#171717',
      yellow1: '#FFF5B5',
      yellow2: '#FDE971',
      yellow3: '#FCDB51',
      yellow4: '#FED83B',
      yellow5: '#FBCB29',
      orange1: '#F1B553',
      orange2: '#F2AF17',
      orange3: '#F0A813',
      orange4: '#E68B07',
      orange5: '#B86D00',
      orange6: '#ac6204',
      black: '#1D1D1D',
      lightRed: '#ffe4e5',
      errorRed: 'hsl(354, 85%, 44%)',
      lightGreen: '#eefeef',
      successGreen: '#3b8239'
    }
  }