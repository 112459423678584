import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";
import { useState } from "react";
import Loader from "../../../Components/Loader/loader.component";
import { disableCompany } from "../../../Utils/Companies/editCompany/deactivateCompany";

// Deactivates a company
const DeactivateCompanyButton = ({id, setSuccessState, setIsOpen}) => {
    const [loading, setLoading] = useState(false);
    
    const editCompany = async(e) => {
        e.preventDefault();
        await disableCompany(id, setIsOpen, setSuccessState, setLoading)
    }

    return (  
        <>
        {loading ? <Loader /> : 
        <SecondaryButton functionName={editCompany} span="Deactivate Company" />}
        </>
    );
}
 
export default DeactivateCompanyButton;