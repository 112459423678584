import { useNavigate } from "react-router-dom";
import PrimaryButton from "../Components/Buttons/primaryButton.component";
import { UnauthMain } from "./unauthorized.styles";

const Unauthorized = () => {
    const navigate = useNavigate();
    const goHome = (e) => {
        e.preventDefault()
        navigate('/');
    }
    return (    
        <UnauthMain>
            <div>
            <h2>You are unauthroized</h2>
            <PrimaryButton functionName={goHome} span="Go Home"/>
            </div>
        </UnauthMain>
    );
}
 
export default Unauthorized;
