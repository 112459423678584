import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { httpGetSingleCourse } from "../../../Hooks/courses.hooks";
import EditCourseSubmit from "./course-edit-submit.component";
import Modal from "../../../Components/Modal/modal.component";
import { FormContainer, SubmitContainer} from "../../CreateRoutes/CreateForm.styles";
import DeleteCourseButton from "./course-delete.component";
import QuestionContainerComponent from "../../FormElements/QuestionElements/QuestionContainer.component";
import TagsSelectedContainer from "../../FormElements/TagElement/TagsContainer.component";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { AuthCheckAdmin } from "../../../Utils/AuthCheck/adminCheck";
import InputEditCourses from "./FormEditCourse/InputsEditCourse.component";

// Form for editing a course
const EditCourse = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [course, setCourse] = useState([]);
    const [courseName, setCourseName] = useState();
    const [price, setPrice] = useState();
    const [description, setDescription] = useState();
    const [expiryDate, setExpiryDate] = useState();
    const [certificatePrefix, setCertificatePrefix] = useState();
    const [tags, setTags] = useState();
    const [image, setImage] = useState('');
    const [questions, setQuestions] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [successState, setSuccessState] = useState('');
    const [pdf, setPdf] = useState('');
    const [video, setVideo] = useState();
    const [videoLinks, setVideoLinks] = useState([]);
    const [doc, setDoc] = useState();
    const [documentation, setDocumentation] = useState([]);
    const [notes, setNotes] = useState('');
    const [newPdf, setNewPdf] = useState('');   
    const [newImage, setNewImage] = useState('');

    useEffect(() => {
        const GetCourse = async() => {
            let c = await httpGetSingleCourse(id);
            if(c){
                setCourse(c);
                setCourseName(c.courseName);
                setPrice(c.price);
                setDescription(c.description);
                setExpiryDate(c.expiryDate);
                setDocumentation(c.documentation);
                setNotes(c.notes);
                setVideoLinks(c.videoLinks);
                setCertificatePrefix(c.certificatePrefix);
                setTags(c.tags);
                setQuestions(c.quiz);
                setImage(c.image);
                setPdf(c.pdf);
            }
        }
        GetCourse();
    }, [id]);

    return (  
        <AuthCheckAdmin>
        <FormContainer>
        <h2>Edit Course</h2>
        <hr />
        <form>
        {/* Main form inputs */}
        <InputEditCourses incomingProps={{courseName, description, price, expiryDate, certificatePrefix,
         notes, video, doc, setCertificatePrefix, setCourseName, setDescription, setPrice,
        setNotes, videoLinks, documentation, setExpiryDate, setVideo, setDoc, setVideoLinks, setDocumentation,
        pdf, setNewImage, setNewPdf, image
        }}/>
        {/* Tags */}
        <TagsSelectedContainer tags={tags} setTags={setTags} />
        {/* Quiz */}
        <QuestionContainerComponent questions={questions} setQuestions={setQuestions}/>
        {/* Popup modal for when the form submission happens */}
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        {successState &&  <p>{successState}</p>}
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
        <DeleteCourseButton setSuccessState={setSuccessState} setIsOpen={setIsOpen} id={id} />
        <EditCourseSubmit
        pdf={newPdf}
        videoLinks={videoLinks}
        documentation={documentation}
        notes={notes}
        questions={questions} 
        courseName={courseName}
        tags={tags}
        image={newImage}
        description={description}
        price={price}
        expiryDate={expiryDate}
        certificatePrefix={certificatePrefix}
        setIsOpen={setIsOpen}
        setSuccessState={setSuccessState}
        />
        </SubmitContainer>
        </form>
        </FormContainer>
        </AuthCheckAdmin>
    );
}
 
export default EditCourse;