import InputDiv from "../../../FormElements/InputText";
import TextareaDiv from "../../../FormElements/Textarea";
import CompanyLogo from "../../../FormElements/CompanyLogo";
import PropTypes from 'prop-types';

const EditCompanyInputs = ({company, setCompany, setLogo}) => {

    return (  
        <>
        <InputDiv title="Company Name" value={company?.companyName} name="companyName" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Main Contact" value={company?.mainContact} name="mainContact" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Secondary Contact" value={company?.secondaryContact} name="secondaryContact" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Office Phone Number" value={company?.phoneOffice} name="phoneOffice" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Mobile Phone Number" value={company?.phoneMobile} name="phoneMobile" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Website" value={company?.website} name="website" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Address" value={company?.address} name="address" nestedValue={setCompany} upperValue={company} />
        <TextareaDiv title="Notes" value={company?.notes} setValue={setCompany} />
        <CompanyLogo company={company} setLogo={setLogo} />
        <p>Created at: {company?.createdAt}</p>
        <br />
        <p>Updated at: {company?.updatedAt}</p>
        </>
    );
}
EditCompanyInputs.propTypes = {
    company: PropTypes.shape({
        companyName: PropTypes.string,
        mainContact: PropTypes.string,
        secondaryContact: PropTypes.string,
        phoneOffice: PropTypes.string,
        phoneMobile: PropTypes.string,
        website: PropTypes.string,
        address: PropTypes.string,
    }).isRequired,
    setCompany: PropTypes.func.isRequired,
    setLogo: PropTypes.func.isRequired,
};
 

export default EditCompanyInputs;