import { httpDeleteUser } from '../../../Hooks/admin.hooks';

export const deleteUser = async (id, setSuccessState, setIsOpen, setLoading) => {
    setLoading(true);
    try {
        const res = await httpDeleteUser(id);
        const data = await res.json();
        if (res.ok) {
            setSuccessState('User has been deleted');
        } else {
            setSuccessState(data.msg);
        }
    } catch (error) {
        setSuccessState('An error occurred while deleting the user');
        console.error('Error deleting user:', error);
    } finally {
        setIsOpen(true);
        setLoading(false);
    }
};