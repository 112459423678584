import { httpGetAllCompanies } from "../../../Hooks/admin.hooks";

export const fetchCompanies = async(id, setCompanies, setSuccess) => {
    try {
        const found = await httpGetAllCompanies(id);
        if (found) {
            setCompanies(found);
        } else {
            setSuccess('No companies found');
        }
    } catch (error) {
        setSuccess('An error occurred while fetching the companies');
        console.error('Error fetching companies:', error);
    }
}
