import { useState, useEffect } from "react";
import { fetchUsers } from "./getUsers";

const useUsers = (id) => {
    const [users, setUsers] = useState([]);
    const [successState, setSuccessState] = useState('');

    useEffect(() => {
        fetchUsers(id, setUsers, setSuccessState);
    }, [id]);

    return { users, successState, setUsers, setSuccessState};
};

export default useUsers;