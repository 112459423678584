import { httpDeleteCourse } from "../../../Hooks/admin.hooks";

export const deleteCourse = async({id, setLoading, setIsOpen, setSuccessState}) => {
    setLoading(true);
    try{
        let res = await httpDeleteCourse(id);
        let data = await res.json();
        if(res.ok) {       
            setSuccessState('Course has been deleted');
            setIsOpen(true);
            setLoading(false);
           } else {
            setSuccessState(data.msg);
           }
    } catch(err){
            setSuccessState('An error has occured deleting the course');
    } finally {
            setIsOpen(true);
            setLoading(false);
    }
}