import { httpCreateCompany, httpCompanyLogo } from "../../../Hooks/admin.hooks";
import { formatNum } from "../../../Helpers/formatPhoneNumber";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import Loader from "../../../Components/Loader/loader.component";
import { useState } from "react";

// Button Functionality for Creating a company
const CreateCompanySubmit = ({company, image, setResult, notes, setIsOpen, user}) => {

    const [loading, setLoading] = useState(false);
    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        const {phoneMobile, phoneOffice} = company;
        const companyPhone = await formatNum(phoneOffice);
        const mobilephone = await formatNum(phoneMobile);
        const newCompany = {
            ...company,
            employees: [],
            active: true,
            notes: notes,
            phoneMobile: mobilephone,
            phoneOffice: companyPhone
        };
        const res = await httpCreateCompany(newCompany, user);
        const data = await res.json();
        if(res.ok){
            if(image){
                const formData = new FormData();
                formData.append('logo', image);
                formData.append('company', company.companyName);
                const res = await httpCompanyLogo(formData);
                if(res.ok){
                    setResult('Company has been created with logo');
                    setIsOpen(true);
                    setLoading(false);
                } else {
                    setResult('An error occurred while creating the company, issue with logo');
                setIsOpen(true);
                setLoading(false);
                }
            } else {
                setResult('Company has been created');
                setIsOpen(true);
                setLoading(false);
            }
        } else {
            setResult(data.msg);
            setLoading(false);
            setIsOpen(true)
        }
    }

    return (  
        <>
            {
            loading ? <Loader /> : <PrimaryButton functionName={handleSubmit} span="Create Company" />
            }
        </>
    );
}
 
export default CreateCompanySubmit;