import TagSelect from "./Tags";
import TertiaryButton from "../../../Components/Buttons/tertiaryButton.component";

// Displays the current selected tages
const TagsSelectedContainer = ({tags, setTags}) => {
    return ( 
        <>
        <h4>Tags</h4>
        <hr />
        {tags?.map((t) => {
           return <div style={{display: "flex", gap: "2rem", justifyContent: "space-between", alignItems: "center"}}>
                <p value={t} style={{fontSize: '1.3em', fontWeight: '500'}}>{t}</p> 
                <TertiaryButton functionName={(e) => {
                    e.preventDefault();
                    let filtered = tags.filter((ti => ti !== t));
                    setTags(filtered);
                }} span="Delete Tag" />
            </div>
        })}
        <TagSelect tags={tags} setTags={setTags}/>
        </>
     );
}
 
export default TagsSelectedContainer;