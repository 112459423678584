import { InputContainer } from "./FormElements.styles";

// Input Number Component: title, placeholder, value, setValue
const InputNumber = (props) => {
    
    return ( 
        <InputContainer>
            <label>{props?.title} <span className="detail">{props?.detail}</span></label>
            <input min="0" type="number" value={props?.value} onChange={(e) => props.setValue(e.target.value)}/>
        </InputContainer>
     );
}
 
export default InputNumber;