import Auth from "../../Helpers/auth";
import { ButtonContainer } from "../Completed/quiz-completed.styles";
import { useNavigate, useParams } from "react-router-dom";
import {useState, useEffect} from 'react';
import Certificate from "../Certificate/certificate.component";
import { httpGetCertificateInfo } from '../../Hooks/users.hooks.js';
import SecondaryButton from "../../Components/Buttons/secondaryButton.component";


const DisplayUser = ({courseName}) => {
    const { data } = Auth.getProfile();
    const {id} = useParams();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState();
    useEffect(() => {
      const FindInfo = async() => {
        let found = await httpGetCertificateInfo(data.id, id);
        setUserInfo(found);
      };
      FindInfo();
    }, [data.id, id]);

    return ( 
        <>
          <h2 >
          Congrats!
          <br />
          You have completed 
          </h2>
          <h2 className="courseName" style={{textTransform: 'uppercase'}}>{courseName}</h2>
        <ButtonContainer>
          <SecondaryButton functionName={() => navigate("/user")} span="Go Home" />
                 <Certificate
                name={data?.firstName + " " + data?.lastName}
                course={userInfo?.courses[0]?.courseName}
                dateCompleted={userInfo?.courses[0]?.date}
                expiryDate={userInfo?.courses[0]?.expiryDate}
                certNumber={userInfo?.courses[0]?.certNum}
                />
         
        </ButtonContainer>
        </>
     );
}
 
export default DisplayUser;