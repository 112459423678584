
const CompanyLogo = ({company, setLogo}) => {
    return ( 
        <div style={{display: 'flex', gap: '1.5rem', marginBlock: '1rem', flexDirection: 'column'}}>
        <label>Company Logo</label>
        <img src={company?.logo} alt="Company Logo" />
        <div>
            <label style={{paddingRight: '1rem'}}>Change Logo</label>
            <input type="file" onChange={(e) => setLogo(e.target.files[0])} />
        </div>
        </div>
     );
}
 
export default CompanyLogo;