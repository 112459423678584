import { httpDisableCompany } from "../../../Hooks/companies.hooks";

export const disableCompany = async({setLoading, id, setSuccessState, setIsOpen}) => {
    setLoading(true);
    try{
        let res = await httpDisableCompany(id);
        let data = await res.json();
        if(res.ok){
            setSuccessState('Company has been deactivated');
        } else {
            setSuccessState(data.msg);
        }

    } catch(err){
        setSuccessState('Company could not be deactivated')
    } finally {
        setIsOpen(true);
        setLoading(false);
    }
}


        
      