import InputDiv from "../../../FormElements/InputText";
import { format } from "date-fns";
import PropTypes from 'prop-types';

const InputsForEditUser = ({user, password, setPassword, setUser}) => {
    const formattedCreatedAt = user?.createdAt ? format(new Date(user?.createdAt), 'yyyy-MM-dd HH:mm:ss') : '';
    const formattedUpdatedAt = user?.updatedAt ? format(new Date(user?.updatedAt), 'yyyy-MM-dd HH:mm:ss') : '';
    return ( 
        <>
         <InputDiv title="First Name" value={user?.firstName} name="firstName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Last Name" value={user?.lastName} name="lastName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Email" value={user?.email} name="email" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Password" value={password} name="password" setValue={setPassword} />
        <p>Type: <span>{user?.type}</span></p>
        {user.company ? <p>Company: <span>{user?.company[0]?.companyName}</span></p> : <span>User has not been assigned to a company</span>}
        <p>Created at: <span>{formattedCreatedAt}</span></p>
        <p>Last Updated at: <span>{formattedUpdatedAt}</span></p>
        <p>Courses: {user?.courses?.map((c) => {
            return <span style={{color: c?.completed ? 'green' : 'red'}} key={c?.courseId}>{c?.courseName}, </span>
        })}</p>
        </>
     );
};

InputsForEditUser.propTypes = {
    user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        type: PropTypes.string,
        company: PropTypes.arrayOf(PropTypes.shape({
            companyName: PropTypes.string,
        })),
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        courses: PropTypes.arrayOf(PropTypes.shape({
            courseId: PropTypes.string,
            courseName: PropTypes.string,
            completed: PropTypes.bool,
        })),
    }).isRequired,
    password: PropTypes.string.isRequired,
    setPassword: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
};
 
export default InputsForEditUser;