import SecondaryButton from "../../Components/Buttons/secondaryButton.component";
import { ArrayContainer } from "./FormElements.styles";

// Any Input that comes into an array
const ArrayInput = ({label, single, arrayItems = [], setSingle, setArray}) => {
    
    return (  
        <ArrayContainer>
            <h4>{label}</h4>
            <div className="mappedElements">
            {arrayItems?.map((i, k) => {
                return <div key={k} style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1em'}}>
                     <p style={{fontSize: '1.2rem'}}> - {i}</p>
                     <SecondaryButton functionName={(e) => {
                        e.preventDefault();
                        const filtered = arrayItems?.filter((item) => {
                            return item !== i
                        });
                        setArray(filtered)
                     }} span="Delete" />
                     </div>
            }
            )}
            </div>
       <div className="inputDiv">
       <input value={single} onChange={(e) => setSingle(e.target.value)}/>
       <SecondaryButton functionName={(e) => {
            e.preventDefault();
            setArray([...arrayItems, single]);
            setSingle('');
        }} span={'Add '+ label} />
       </div>
        </ArrayContainer>
    );
}
 
export default ArrayInput;