import {Outlet} from 'react-router-dom'
import { MainContainer } from './base.styles';
import { Toaster } from 'react-hot-toast';

const BasePage = () => {
    return ( 
        <MainContainer>
        <Toaster position='bottom-left' reverseOrder={false} />
        <Outlet />
        </MainContainer>
     );
}
 
export default BasePage;