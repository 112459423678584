import { useParams } from "react-router-dom";
import { useState } from "react";
import { FormContainer } from "../../CreateRoutes/CreateForm.styles";
import Modal from "../../../Components/Modal/modal.component";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { AuthCheckAdmin } from "../../../Utils/AuthCheck/adminCheck";
import EditFormButtons from "./Form/editButtons.component";
import EditCompanyInputs from "./Form/editInputs.component";
import useCompany from '../../../Utils/Companies/singleCompany/useCompany';

// Form for editing a company
const EditCompany = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [logo, setLogo] = useState('');
    const { company, successState, setSuccessState, setCompany } = useCompany(id);

    const gotoManagers = () => {
        navigate(`/admin/edit-company/${id}/managers-employees`);
    }

    return (
        <AuthCheckAdmin>
        <FormContainer>
        <h2>Edit Company</h2>
        <hr />
        <form>
        {/* The inputs for the form */}
        <EditCompanyInputs company={company} setCompany={setCompany} setLogo={setLogo} />
        {/* Pop up Modal when the course has been submitted */}
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        {successState && <p>{successState}</p>}
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        {/* Shows the buttons on the form */}
        <EditFormButtons company={company} gotoManagers={gotoManagers} setIsOpen={setIsOpen} setSuccessState={setSuccessState} logo={logo}/>
        </form>
        </FormContainer>
        </AuthCheckAdmin>
      );
}
 
export default EditCompany;