import { Outlet } from "react-router-dom";

const CourseBase = () => {
    return (
        <>
        <Outlet/>
        </>
      );
}
 
export default CourseBase;