import CompanyAdmin from "../../CreateRoutes/CreateCompany/CompanyAdmin/company-admin.component";
import { useState } from "react";
import CreateManagerSubmit from "./create-manager-submit.component";

const CreateManager = () => {

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    
    const [type, setType] = useState('Manager');

    return (  
        <section>
        <CompanyAdmin title="Create manager or employee" user={user} setUser={setUser} type={type} setType={setType}/>
        <CreateManagerSubmit user={user} type={type}/>
        </section>
    );
}
 
export default CreateManager;