import InputDiv from "../../../FormElements/InputText";
import ArrayInput from "../../../FormElements/ArrayInput";
import TextareaDiv from "../../../FormElements/Textarea";
import InputNumber from "../../../FormElements/InputNumber";
import PdfInput from "../../../FormElements/PdfInput.component";
import ImageInput from "../../../FormElements/ImageInput.component";


const InputEditCourses = ({incomingProps}) => {
    const {courseName, description, price, expiryDate, certificatePrefix,
        notes, video, doc, setCertificatePrefix, setCourseName, setDescription, setPrice,
        setNotes, videoLinks, documentation, setExpiryDate, setVideo, setDoc, setVideoLinks, setDocumentation,
        pdf, setNewImage, setNewPdf, image
    } = incomingProps;
    return (  
        <>
        {/* Course Name */}
        <InputDiv value={courseName} title="Course Name" setValue={setCourseName} />
        {/* Description */}
        <TextareaDiv value={description} title="Course Description" setValue={setDescription} />
        {/* Price */}
        <InputNumber value={price} title="Course Price" setValue={setPrice} />
        {/* Expiry Date */}
        <InputNumber value={expiryDate} title="Expiry Date" detail="* in years" setValue={setExpiryDate} />
        {/* Certificate Prefix */}
        <InputDiv value={certificatePrefix} title="Certificate Prefix" detail="* format: FP-" setValue={setCertificatePrefix} placeholder="FP-"/>
        {/* Notes about the course */}
         <TextareaDiv value={notes} title="Notes" setValue={setNotes} />
        {/* Video Links */}
        <ArrayInput label="Video Links" single={video} arrayItems={videoLinks} setSingle={setVideo} setArray={setVideoLinks}/>
         {/* Documentation */}
         <ArrayInput label="Documentation" single={doc} arrayItems={documentation} setSingle={setDoc} setArray={setDocumentation}/>
          {/* PDF Upload */}
        <PdfInput pdf={pdf} setNewPdf={setNewPdf}/>
          {/* Image Input */}
          <ImageInput image={image} setNewImage={setNewImage}/>
        </>
    );
}
 
export default InputEditCourses;