import InputContainer from "../authComponents.jsx/Input";
import { useState } from "react";
import PrimaryButton from "../../Components/Buttons/primaryButton.component";
import { httpForgotPassword } from "../../Hooks/users.hooks";
import { AuthContainer, AuthContainer2, AuthenticationSide } from "../auth.styles";
const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [err, setErr] = useState('');
    const [success, setSuccess] = useState('');
    
    const handleChange = (e) => {
        setEmail(e.target.value);
    }

    const forgotPass = async(e) => {
        e.preventDefault();
        setSuccess('');
        setErr('');
        if (email.length < 9 || !email.includes('@')) {
            setErr('Not a valid email');
            return;
        }
        try{
            let res = await httpForgotPassword(email);
            let data = await res.json();
            if(res.ok){
                setSuccess('Password reset link has been sent to your email');
            } else {
                setErr(data.msg);
            }
        } catch (err){
            setErr('An error has occured');
        }
    }
    return ( 
        <>
        <AuthContainer style={{width: '90%'}}>
        <AuthContainer2 style={{width: '90%'}}>
        <AuthenticationSide className="boxShadow" style={{alignItems:'start'}}>
        <h2 style={{marginLeft: '1rem'}}>Forgot Password</h2>
        {err && <p style={{color: 'red'}}>{err}</p>}
        {success && <p style={{color: 'green'}}>{success}</p>}
        <form>
        <InputContainer placeholder="johndoe@yoda.com" label="Email" value={email} name='email' onChange={handleChange}/>
        <PrimaryButton functionName={forgotPass} span="Reset Password" />
        </form>
        </AuthenticationSide>
        </AuthContainer2>
        </AuthContainer>
        </>
     );
}
 
export default ForgotPassword;