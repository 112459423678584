import CompletedCourse from "../UserCourses/CompleteCourse.component";
import IncompleteCourse from '../UserCourses/IncompleteCourse.component';
import AllCourses from "../UserCourses/AllCourses.component";
import { useState, useEffect } from "react";

const UserRouterComponent = ({courses, user, chosen}) => {
    const[chosenItem, setChosenItem] = useState(<AllCourses courses={courses} data={user}/>);
    
    useEffect(() => {
        if(chosen === 'AllCourses'){
            setChosenItem(<AllCourses courses={courses} data={user}/>)
        } else if (chosen === 'IncompleteCourses'){
           setChosenItem( <IncompleteCourse courses={courses} data={user}/>)
        } else if (chosen === 'CompleteCourses'){
            setChosenItem(<CompletedCourse courses={courses} data={user}/>)
        } else {
            setChosenItem(<AllCourses courses={courses} data={user}/>)
        }
    }, [chosen, user, courses])

    return (  
        <>
        {chosenItem}
        </>
    );
}
 
export default UserRouterComponent;