import Burger from "./burger.component";
import Menu from "./burgerMenu.component";
import { useOnClickOutside } from "./hook";
import { useState, useRef } from "react";
import { OuterBurgerDiv } from "./burgerMenu.styles";

const OuterBurger = () => {
    const [open, setOpen] = useState(false);
    const node = useRef();
    useOnClickOutside(node, () => setOpen(false));
    return ( 
        <OuterBurgerDiv ref={node}>
         <Burger open={open} setOpen={setOpen} />
         <Menu open={open} setOpen={setOpen}/> 
        </OuterBurgerDiv>
     );
}
 
export default OuterBurger;