import styled from 'styled-components';

export const NewBurger = styled.div`
 

`


// export const StyledBurger = styled.button`
//   position: absolute;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   background: transparent;
//   border: none;
//   cursor: pointer;
//   top: 3%;
//   padding: 0;
//   z-index: 30;
//   &:focus {
//     outline: none;
//   }
//   div, svg {
//     min-width: 3rem;
//     width: 4rem;
//     min-height: 0.25rem;
//     height: 0.25rem;
//     background: ${({ open, theme }) => open ? theme.colors.yellow4 : theme.colors.offWhite};
//     border-radius: 5px;
//     transition: all 0.3s linear;
//     position: relative;
//     transform-origin: 1px;
//     z-index: 30;
//     :first-child {
//       transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
//     }
//     :nth-child(2) {
//       opacity: ${({ open }) => open ? '0' : '1'};
//       transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
//     }
//     :nth-child(3) {
//       transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
//     }
//   }
//   @media screen and (width >= 1040px){
//     /* Fixes the weird transition between the states */
//     top: calc(100% - 97%);
//     div{
//     margin-top: 0.5rem;
//     }
//   }
 
//   @media screen and (width <= 1100px){
//         top: calc(100% - 96%);
//         width: 1rem;
//         min-width: 1rem;
//         height: 2rem;
//         min-height: 2rem;
//         div{
//           min-width: 3rem;
//           width: 3rem;
//           min-height: 0.25rem;
//           height: 0.25rem;
//         } 
//     } 
//     @media screen and (width <= 600px){
//       top: calc(100% - 96.5%);
//       div{
//           min-width: 2.5rem;
//           width: 2.5rem;
//           min-height: 0.25rem;
//           height: 0.25rem;
          
//         }
//     }
// `