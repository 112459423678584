import DisplayAnswersEach from "./displayAnswers.component";
import { EachQuestionContainer } from "../quizStyles.styles";


// Displays each answer
const DisplayAnswersComponent = ({question, idx}) => {
    return ( 
        <>
            <EachQuestionContainer key={question?._id}>
              <div key={question?._id}>
              <h3>
                {idx + 1}. {question?.question}
              </h3>
              {question?.answer1 && (
                <DisplayAnswersEach
                  corrrectAnswer={question?.correctAnswer}
                  answer={question?.answer1}
                />
              )}
              {question?.answer2 && (
                <DisplayAnswersEach
                  corrrectAnswer={question?.correctAnswer}
                  answer={question?.answer2}
                />
              )}
              {question?.answer3 && (
                <DisplayAnswersEach
                  corrrectAnswer={question?.correctAnswer}
                  answer={question?.answer3}
                />
              )}
              {question?.answer4 && (
                <DisplayAnswersEach
                  corrrectAnswer={question?.correctAnswer}
                  answer={question?.answer4}
                />
              )}
              </div>
            </EachQuestionContainer>
        </>
     );
}
 
export default DisplayAnswersComponent;