import { Link } from "react-router-dom";
import { useState } from "react";
import AdminTabsComponent from "./adminTabs.component";
import {
  LeftAdmin,
  RightAdmin,
  TopLeftAdmin,
  BottomLeftAdmin,
  AdminContainer,
  AdminTable,
} from "./admin.styles";
import CoursesTab from "./Courses/coursesTab.component";
import UsersTab from "./Users/userTab.component";
import CompaniesTab from "./Companies/companiesTab.component";
import Auth from "../../Helpers/auth";
import Unauthorized from "../../Unauthorized/unauthorized.component";
import {AuthCheckAdmin} from '../../Utils/AuthCheck/adminCheck';
// Admin Base Page
const Admin = () => {

  const [showCompanies, setShowCompanies] = useState(true);
  const [showCourses, setShowCourses] = useState(false);
  const [showUsers, setShowUsers] = useState(false);

  return (
    <AuthCheckAdmin>
    <AdminContainer>
      {Auth.loggedIn() ? (
        <>
          <LeftAdmin>
            <TopLeftAdmin>
              <h2>Admin Dashboard</h2>
            </TopLeftAdmin>
            <BottomLeftAdmin>
              <Link to="/admin/create-user">Create User</Link>
              <Link to="/admin/create-company">Create Company</Link>
              <Link to="/admin/create-course">Create Course</Link>
            </BottomLeftAdmin>
          </LeftAdmin>
          <RightAdmin>
            <AdminTabsComponent
              setShowCompanies={setShowCompanies}
              showCompanies={showCompanies}
              setShowCourses={setShowCourses}
              showCourses={showCourses}
              setShowUsers={setShowUsers}
              showUsers={showUsers}
            />
            <AdminTable>
              {showCompanies && <CompaniesTab />}
              {showCourses && <CoursesTab />}
              {showUsers && <UsersTab />}
            </AdminTable>
          </RightAdmin>
        </>
      ) : (
        <>
          <Unauthorized />
        </>
      )}
    </AdminContainer>
    </AuthCheckAdmin>
  );
};

export default Admin;
