import styled from "styled-components";

export const QuizContainer = styled.div`
    width: 95%;
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    /* border: 1px solid orange; */
    padding: 0.5rem;
    border-radius: 5px;
    background-color: ${({theme}) => theme.colors.offWhite};
    .item{
        font-size: 1.4rem;
    }
    .list{
        font-size: 1.3rem;
    }
`;
export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 1.5rem;
`;

export const QuestionContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 1em;
    width: 95%;
    border-radius: 5px;
    margin: 1rem auto;
    border: 3px solid ${({theme}) => theme.colors.grey};
    p{
        font-size: 1.3rem;
        border-bottom: 1px solid ${({theme}) => theme.colors.grey};
    }

`;