import { NavLink, Link } from "react-router-dom";
import Logo from "../../../Visuals/Images/Logo.png";
import { Header } from "./navbar.styles";
import OuterBurger from "./outerBuger.component";
import { useNavigate } from "react-router-dom";
import Auth from "../../../Helpers/auth";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";

const NavBar = () => {
  const navigate = useNavigate();

  return (
    <Header className="boxShadow">
      <nav className="topNav">
        <div className="first">
          <OuterBurger />
        </div>
        <div className="second">
          <NavLink to="/">
            <img
              className="logoImage"
              src={Logo}
              alt="Yoda Safety Services Logo"
            />
          </NavLink>
        </div>

        <div className="third">
          {Auth.loggedIn() ? (
            <>
              <div className="mobile">
                {/* <button className='primaryButton boxShadow' onClick={(e) => {
                        e.preventDefault();
                        Auth.logout();
                    }}><span>LOGOUT</span></button> */}
                <NavLink to="/user" title="User Home">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="icon icon-user"
                  >
                    <path
                      className="primary"
                      d="M12 12a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                    />
                    <path
                      className="secondary"
                      d="M21 20v-1a5 5 0 0 0-5-5H8a5 5 0 0 0-5 5v1c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2z"
                    />
                  </svg>

                  {/* <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" className="icon icon-home"><path className="primary" d="M9 22H5a1 1 0 0 1-1-1V11l8-8 8 8v10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1zm3-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                <path className="secondary" d="M12.01 4.42l-8.3 8.3a1 1 0 1 1-1.42-1.41l9.02-9.02a1 1 0 0 1 1.41 0l8.99 9.02a1 1 0 0 1-1.42 1.41l-8.28-8.3z"/></svg> */}
                </NavLink>

                <div
                  onClick={(e) => {
                    e.preventDefault();
                    Auth.logout();
                    window.location.assign("/");
                  }}
                  title="Logout"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="icon icon-door-exit"
                  >
                    <path
                      className="primary"
                      d="M11 4h3a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V6h-2v12h2v-2a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1h-3v1a1 1 0 0 1-1.27.96l-6.98-2A1 1 0 0 1 2 19V5a1 1 0 0 1 .75-.97l6.98-2A1 1 0 0 1 11 3v1z"
                    />
                    <path
                      className="secondary"
                      d="M18.59 11l-1.3-1.3c-.94-.94.47-2.35 1.42-1.4l3 3a1 1 0 0 1 0 1.4l-3 3c-.95.95-2.36-.46-1.42-1.4l1.3-1.3H14a1 1 0 0 1 0-2h4.59z"
                    />
                  </svg>
                </div>
              </div>
              {/* Desktop */}
              <div className="desktop">
                <NavLink to="/user" title="User Home">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="icon icon-user"
                  >
                    <path
                      className="primary"
                      d="M12 12a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
                    />
                    <path
                      className="secondary"
                      d="M21 20v-1a5 5 0 0 0-5-5H8a5 5 0 0 0-5 5v1c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2z"
                    />
                  </svg>
                  {/* <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" className="icon icon-home"><path className="primary" d="M9 22H5a1 1 0 0 1-1-1V11l8-8 8 8v10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1zm3-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                <path className="secondary" d="M12.01 4.42l-8.3 8.3a1 1 0 1 1-1.42-1.41l9.02-9.02a1 1 0 0 1 1.41 0l8.99 9.02a1 1 0 0 1-1.42 1.41l-8.28-8.3z"/></svg> */}
                </NavLink>
                <PrimaryButton
                  functionName={(e) => {
                    e.preventDefault();
                    Auth.logout();
                    window.location.assign("/");
                  }}
                  span="LOGOUT"
                />

                {/* <div onClick={(e) => { e.preventDefault(); Auth.logout();}}  title="Logout">
                <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-door-exit"><path className="primary" d="M11 4h3a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V6h-2v12h2v-2a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1h-3v1a1 1 0 0 1-1.27.96l-6.98-2A1 1 0 0 1 2 19V5a1 1 0 0 1 .75-.97l6.98-2A1 1 0 0 1 11 3v1z"/><path className="secondary" d="M18.59 11l-1.3-1.3c-.94-.94.47-2.35 1.42-1.4l3 3a1 1 0 0 1 0 1.4l-3 3c-.95.95-2.36-.46-1.42-1.4l1.3-1.3H14a1 1 0 0 1 0-2h4.59z"/></svg>
                    </div> */}
              </div>
            </>
          ) : (
            //   If not logged in
            <>
              {/* Mobile Login button */}
              {(process.env.REACT_APP_PRODUCTION === undefined ||
                process.env.REACT_APP_PRODUCTION === "false") && (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/login");
                  }}
                  className="mobile"
                  title="Login"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="icon icon-door-enter"
                  >
                    <path
                      className="primary"
                      d="M13 4V3a1 1 0 0 1 1.27-.96l6.98 2A1 1 0 0 1 22 5v14a1 1 0 0 1-.75.97l-6.98 2A1 1 0 0 1 13 21v-1h-3a1 1 0 0 1-1-1v-2a1 1 0 0 1 2 0v1h2V6h-2v1a1 1 0 0 1-2 0V5a1 1 0 0 1 1-1h3z"
                    />
                    <path
                      className="secondary"
                      d="M7.59 11l-1.3-1.3c-.94-.94.47-2.35 1.42-1.4l3 3a1 1 0 0 1 0 1.4l-3 3c-.95.95-2.36-.46-1.42-1.4L7.6 13H3a1 1 0 0 1 0-2h4.59z"
                    />
                  </svg>
                </div>
              )}
              {/* Desktop login button */}
              {(process.env.REACT_APP_PRODUCTION === undefined ||
                process.env.REACT_APP_PRODUCTION === "false") && (
                <div className="desktop">
                  <PrimaryButton
                    functionName={(e) => {
                      e.preventDefault();
                      navigate("/login");
                    }}
                    span="LOGIN"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </nav>
      {(process.env.REACT_APP_PRODUCTION === undefined ||
        process.env.REACT_APP_PRODUCTION === "false") && (
        <div className="bottomNav">
          {Auth.loggedIn() ? (
            <nav className="links">
              <NavLink to="/">About Us</NavLink>
              <div className="dropdown">
                <NavLink to="/online" className="dropbtn">
                  Training
                </NavLink>
                <div className="dropdown-content boxShadow">
                  <Link to="/online">Online</Link>
                  <Link to="/classroom">Classroom</Link>
                </div>
              </div>
              <NavLink to="/program">Program Development</NavLink>
              <NavLink to="/wcb-certified">
                NS WCB Safety Certified Provider
              </NavLink>
              <NavLink to="/insurance">Insured</NavLink>
              <NavLink to="/resources">Resources</NavLink>

              {/* <NavLink to="/online">Training</NavLink> */}
              {/* <NavLink to="/user">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-home"><path className="primary" d="M9 22H5a1 1 0 0 1-1-1V11l8-8 8 8v10a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1zm3-9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                <path className="secondary" d="M12.01 4.42l-8.3 8.3a1 1 0 1 1-1.42-1.41l9.02-9.02a1 1 0 0 1 1.41 0l8.99 9.02a1 1 0 0 1-1.42 1.41l-8.28-8.3z"/></svg>
                </NavLink> */}
            </nav>
          ) : (
            <nav className="links">
              <NavLink to="/">About Us</NavLink>
              <div className="dropdown">
                <NavLink to="/online" className="dropbtn">
                  Training
                </NavLink>
                <div className="dropdown-content boxShadow">
                  <Link to="/online">Online</Link>
                  <Link to="/classroom">Classroom</Link>
                </div>
              </div>
              <NavLink to="/program">Program Development</NavLink>
              <NavLink to="/wcb-certified">
                NS WCB Safety Certified Provider
              </NavLink>
              <NavLink to="/insurance">Insured</NavLink>
              <NavLink to="/resources">Resources</NavLink>
              {/* <NavLink to="/online">Training</NavLink> */}
            </nav>
          )}
        </div>
      )}
    </Header>
  );
};

export default NavBar;
