
const ImageInput = ({image, setNewImage}) => {
    return ( 
        <>
         <div style={{display: 'flex', justifyContent: 'space-between', marginBlock: '1rem', paddingBottom: '1rem', borderBottom: '1px solid #e6e6e6'}}>
        <label>Thumbnail Picture</label>
        <img src={image} alt={`Thumbnail`} />
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <label>Change Thumbnail</label>
        <input type="file" accept="image/png, image/jpg" onChange={(e) => setNewImage(e.target.files[0])} />
        </div>
        </div>
        </>
     );
}


export default ImageInput;