import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Auth from '../../Helpers/auth';
import { QuizCompleteContainer, ResultsContainer, ReviewQuizContainer } from "../Completed/quiz-completed.styles";
import { httpGetSingleCourse } from "../../Hooks/courses.hooks";
import { useQuery } from "@tanstack/react-query";
import UserIncompleteComponent from "../DisplayUserInfo/displayUserIncomplete.component";
import DisplayAnswersComponent from "../DisplayAnswers/displayAns.component";

// Component for seeing the answers for incompleted course
const IncompleteCourse = () => {
    const {id} = useParams();

    const navigate = useNavigate();
    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        }
    }, [navigate]);

    const { data, error, isLoading } = useQuery({
        queryKey: ['userId', id],
        queryFn: () => httpGetSingleCourse(id),
        enabled: !!id,
        retry: 5,
      });

    if(error){
          return <h3>An error has occured</h3>
    }
     if(isLoading){
         return <h3>Loading...</h3>
    }

    return (  
        <QuizCompleteContainer>
            <ResultsContainer>
            <UserIncompleteComponent id={id} />
            </ResultsContainer>
            <ReviewQuizContainer>
            <h2>Review Quiz</h2>
            <hr />
            {data?.quiz?.map((question, idx) => {
                return (
                 <DisplayAnswersComponent question={question} idx={idx} />

                )
            })} 
            </ReviewQuizContainer>
        </QuizCompleteContainer>
    );
}
 
export default IncompleteCourse;