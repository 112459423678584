import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { httpCreateUser } from "../../../Hooks/admin.hooks";
import { useState } from "react";
import Loader from "../../../Components/Loader/loader.component";

// Handles the submit for creating a user
const CreateUserSubmit = ({user, setIsOpen, setSuccessState}) => {
    const [loading, setLoading] = useState(false);
    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        const newUser = Object.assign(user, {
            type: "User",
            courses: []
        });
        let res = await httpCreateUser(newUser);
        let data = await res.json();
        if(res.ok){
            setSuccessState('User has been created');
            setIsOpen(true);
            setLoading(false);
        } else {
            setSuccessState(data.msg);
            setIsOpen(true);
            setLoading(false);
        }
    }
    return ( 
        <>
        {loading ? <Loader /> : <PrimaryButton functionName={(e) => handleSubmit(e)} span="Create User" />}
        </>
     );
}
 
export default CreateUserSubmit;