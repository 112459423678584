import { useState } from "react";
import { httpSignUpUser} from "../Hooks/users.hooks";
import {useNavigate} from 'react-router-dom';
import Auth from '../Helpers/auth';
import AuthImage from '../Visuals/Images/Authentication.jpg';
import { AuthContainer, PictureSide, AuthenticationSide, EachInput, AuthContainer2  } from "./auth.styles";
import PrimaryButton from "../Components/Buttons/primaryButton.component";
import TertiaryButton from "../Components/Buttons/tertiaryButton.component";
import InputContainer from "./authComponents.jsx/Input";
import PasswordInput from "./authComponents.jsx/PasswordInput";

// Form for signing up
const SignUpComponent = ({setPage}) => {
    
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const [verifyShowPass, setVerifyShowPass] = useState(false);
    const [error, setError] = useState('');
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        verifyPassword: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });
        if(error.length){
            setError('');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        if(formState.firstName.length < 2){
            setError('First Name must be longer than 3 characters');
            return;
        }
        if(formState.lastName.length < 3){
            setError('Last Name must be longer than 3 characters');
            return;
        }
        if(formState.email.length < 6 || !formState.email.includes('@') ){
            setError('Not a valid email');
            return;
        }
        if(formState.password.length < 6){
            setError('Password is not long enough');
            return;
        }
        if(formState.password !== formState.verifyPassword){
            setError('Passwords do not match');
            return;
        }
        const response = await httpSignUpUser(formState);
        const data = await response.json();
        if (response.ok) {
            try{
                Auth.login(data.token);
            } catch(err){
                setError(data.msg);
                return;
            }
            navigate('/user');
         } else {
           setError('Your account has not been created')
        }
         setFormState({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            verifyPassword: ''
       });
    };
    return ( 
        <AuthContainer>
        <AuthContainer2>
        <PictureSide className="boxShadow">
        <img src={AuthImage} alt="Yellow Helmets" />
        </PictureSide>
        <AuthenticationSide className="boxShadow">
        <form onSubmit={handleFormSubmit}>
            <h2>Sign Up</h2>
            <div className="nameDiv">
            {/* First Name */}
            <InputContainer label="First Name" value={formState.firstName} name='firstName' onChange={handleChange}/>
            {/* Last Name */}
            <InputContainer label="Last Name" value={formState.lastName} name='lastName' onChange={handleChange} />
            </div>
            {/* Email */}
            <InputContainer label="Email" value={formState.email} name='email' onChange={handleChange} />
            {/* Password */}
            <PasswordInput 
                label="Password" 
                span="Must be at least 6 characters" 
                handleChange={(e) => {
                    e.preventDefault();
                    setShowPass(prev => !prev)}}
                showPass={showPass}
                value={formState.password}
                name='password'
                onChange={handleChange}
            />
            {/* Verify Password */}
            <PasswordInput 
            label="Verify Password"
            handleChange={(e) => {
                e.preventDefault();
                setVerifyShowPass(prev => !prev)}}
            value={formState.verifyPassword}
            onChange={handleChange}
            name='verifyPassword' 
            showPass={verifyShowPass}
            />
            {/* Error Message */}
            {error && <p className="error">{error}</p>}
            <PrimaryButton span="Sign Up" />
            <div className="switchPage">
            <p style={{fontWeight: "800"}}>Have An Account?</p>
            <TertiaryButton functionName={() => setPage(true)} span="Log In" />
            </div>
            </form>
        </AuthenticationSide>
        </AuthContainer2>
        </AuthContainer>
     );
}
 
export default SignUpComponent;