import { useState, useEffect } from "react";
import { fetchCompanies } from "./getCompanies";

const useCompanies = (id) => {
    const [companies, setCompanies] = useState([]);
    const [successState, setSuccessState] = useState('');

    useEffect(() => {
        fetchCompanies(id, setCompanies, setSuccessState);
    }, [id]);

    return { companies, successState, setCompanies, setSuccessState};
};

export default useCompanies;