import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from 'prop-types';

const MappedUsersOrEmployee = ({user, title}) => {
    const navigate = useNavigate();
    const {id} = useParams();

    const editUser = (userId) => {
        navigate(`/admin/edit-company/${id}/managers-employees/${userId}`);
    };

    return ( 
    <>
    <h4>{title}</h4>
    {user?.map((user) => (
            <li key={user?._id}>
                <p>{user?.firstName} {user?.lastName}</p>
                <p>{user?.email}</p>
                {user?.active ? <p style={{color: 'green'}}>active</p> : <p style={{color: 'red'}}>inactive</p>}
                <SecondaryButton functionName={() => editUser(user?._id)} span={`Edit ${user?.type}`} />
            </li>
    ))}
    </> 
    );
};

MappedUsersOrEmployee.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
    })).isRequired,
    title: PropTypes.string.isRequired,
};
 
export default MappedUsersOrEmployee;