import { httpEditCompany, httpCompanyLogo } from "../../../Hooks/admin.hooks";
import { formatNum } from "../../../Helpers/formatPhoneNumber";

export const editCompany = async (e, company, setSuccessState, setIsOpen, setLoading, user, logo) => {
    setLoading(true);
    try {
        const {phoneMobile, phoneOffice} = company;
        const companyPhone = await formatNum(phoneOffice);
        const mobilephone = await formatNum(phoneMobile);
        const updatedCompany = {
            ...company,
            phoneMobile: mobilephone,
            phoneOffice: companyPhone,
        };
        let res = await httpEditCompany(company._id, updatedCompany, user);
        let data = await res.json();
        if(res.ok){
            setSuccessState('Company has been edited');
            if(logo){
                const formData = new FormData();
                formData.append('logo', logo);
                formData.append('companyName', company.companyName);
                await httpCompanyLogo(formData);
            }
        } else {
            setSuccessState(data.msg);
        }
    } catch(err){
        setSuccessState('An error occurred while editing the company');
        console.error('Error editing company:', err);
    } finally{
        setIsOpen(true);
        setSuccessState(false);
    }
}