
const DestructiveButton = ({functionName, span}) => {
    return (  
        <>
        <button className="destructiveButton" onClick={functionName}><span>{span}</span></button>
        
        </>
    );
}
 
export default DestructiveButton;