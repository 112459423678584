import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { useParams } from "react-router-dom";
import Loader from "../../../Components/Loader/loader.component";
import { useState } from "react";
import {editCourse} from '../../../Utils/Courses/editCourse/editCourse';
// Function for editing the course
const EditCourseSubmit = ({questions, pdf, notes, videoLinks, documentation, 
                         image, expiryDate, certificatePrefix, courseName, tags, 
                             description, price, setSuccessState, setIsOpen}) => {

    const {id} = useParams();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        const fullCourse = Object.assign({}, {
            courseName: courseName,
            price: price,
            description: description,
            tags: tags,
            notes: notes,
            videoLinks: videoLinks,
            documentation: documentation,
            quiz: questions,
            expiryDate: expiryDate,
            certificatePrefix: certificatePrefix,
        });
        await editCourse(id, fullCourse, image, pdf, setSuccessState, setIsOpen, setLoading);
    }

    return (
    <>
    {loading ? <Loader /> :
     <PrimaryButton functionName={handleSubmit} span="Edit Course" />}
    </>
    );
}
 
export default EditCourseSubmit;