import { MainContainer,  LightSection, DarkSection,
    TitleSection, SpecificsSection, AboutUsSection, ServicesSection, ServicesContainer, ContactUsSection

 } from "../MainRoute.styles";
import MediumPic from '../../Visuals/Images/medium.jpg';

const AboutUs = () => {

    return ( 
        <MainContainer>
            <LightSection>
            <TitleSection>
            <div className="overlay">
            <img src={MediumPic} alt='tiny' />
            </div>
            <div className="text">
            <h1>Yoda Safety Services Inc</h1>
            <span style={{color: "yellow"}}>Website is currently under development</span>
            </div>
            </TitleSection>
            </LightSection>
            <DarkSection className="boxShadow">
            <SpecificsSection>
            <div>
            <p>WCB approved as a Safety Certified Provider</p>
            </div>
            <div>
            <p>Fully Insured</p>
            </div>
            <div>
            <p>ISO 9001:2015 Certified</p>
            </div>
            <div>
            <p>Certified Trainers and Auditors</p>
            </div>
            </SpecificsSection>
            </DarkSection>
            <LightSection>
            <AboutUsSection>
            {/* <h2>ABOUT US</h2> */}
            <h2>About Us</h2>
            <hr />
            <br/>
            <h4 style={{fontWeight: "700"}}>Jim Benoit President/CEO of Yoda Safety Services Inc.</h4>
            <br />
            <p>Jim owned Occupational Health & Educational Services since 2001 to 2023 a register Safety Certified Provider with the Workers Compensation Board of Nova Scotia. 
                 Jim has started a new Business in August 2023 called Yoda Safety Services Inc and has become a new Safety Certified Provider with the Workers Compensation Board of Nova Scotia as of June 2024. His ideals and philosophy remain the same in providing customer service, quality, dependability, pricing, etc., is on going. 
                 Jim believes in success of any business is having satisfied clients. Open door policy with clients and staff is essential, this is a policy that most companies build their foundation of success on.</p>
            <br />
            <p>Jim is a Certified Auditor and has conducted approximately 3500 Audits during his career within Provincial and Federal jurisdictions, 
                using the approved guidelines/standards of the Nova Scotia Workers Compensation Board of Nova Scotia. He has 25 years of experience in the manufacturing industry 
                and has developed many OSH Health & Safety Programs for the following industries, Construction, Marine, Road Builders, Service i.e. Hotels, Ski Resorts, Welding and Fabrication, Child Care, Farms/Agriculture, 
                Trucking, and Forestry. </p>
            <br />
            <p>Jim has taken training in Modern Safety Management with Dupont and has taken several other courses through this career that has given the competency to achieve credibility within the industry of Health & Safety.  
                Jim also has developed Audit Criteria for a number of operations and has significant experience in OSH Policy Development, designed Safety Training Courses that is specific to industry requirements or he can also develop training courses tailored to clients needs/requirements. 
                Jim is a Certified Trainer and Site Inspector which has been called in by Clients to patrol/inspect work site for compliance and assist/participate in Site Toolbox/Tailgate and JOSH Committee Meetings. 
                He has help may Clients comply with Department of Labour Orders.</p>
            </AboutUsSection>
            </LightSection>
            <DarkSection className="boxShadow">
            <ServicesSection>
            <h2>Summary of Services</h2>
            <ServicesContainer>
                <ul>
                    <li>Provide WCB Safety Certified Audits to all industry sectors</li>
                    <li>Training</li>
                    <li>Health & Saftey Manuels and Program Development</li>
                    <li>Consultation</li>
                </ul>
            {/* <div>
            <p>Provide WCB Safety Certified Audits to all industry sectors</p>
            </div>
            <div>
            <p>Training</p>
            </div>
            <div>
            <p>Health & Saftey Manuels/Programs</p>
            </div>
            <div>
            <p>Consultation</p>
            </div> */}
            </ServicesContainer>
            </ServicesSection>
            </DarkSection>
            <LightSection>
            <ContactUsSection>
            <h2>Contact Us</h2>
            <hr/>
            <br />
            <p>Jim Benoit <span className="italicSpan">President/CEO ASA/CSO/Auditor/Trainer/Site Inspector/Health & Safety Program and Course Development</span></p>
            <p>Phone Number: <span>902-478-1972</span></p>
            <p>jbenoit.yoda@outlook.com</p>
            <br />
            <p>Helen Benoit <span className="italicSpan">Office Admin</span></p>
            <p>Office Phone Number: <span>902-802-9716</span></p>
            <p>hbenoit.yoda@outlook.com</p>
             <br />
            <address>
            825 Ostrea Lake Road <br />
            Ostrea Lake NS <br />
            B0J 2L0
            </address>
            </ContactUsSection>
            </LightSection>
        </MainContainer>
     );
}
 
export default AboutUs;