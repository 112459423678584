import React,{ useState } from "react";
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import { httpLoginUser } from "../Hooks/users.hooks";
import AuthImage from '../Visuals/Images/Authentication.jpg';
import Auth from '../Helpers/auth';
import { AuthContainer, PictureSide, AuthenticationSide, AuthContainer2  } from "./auth.styles";
import PrimaryButton from "../Components/Buttons/primaryButton.component";
import TertiaryButton from "../Components/Buttons/tertiaryButton.component";
import InputContainer from "./authComponents.jsx/Input";
import PasswordInput from "./authComponents.jsx/PasswordInput";

// Form for login
const LoginComponent = ({setPage}) => {
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const [formState, setFormState] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value,
        });
        if(error.length){
            setError('');
        }
    };

    const forgotPassword = (e) => {
        e.preventDefault();
        navigate('/login/forgot-password');
    }

    const handleFormSubmit = async (e) => {
       e.preventDefault();
      
       if(formState.email.length < 6 || !formState.email.includes('@') ){
        setError('Not a valid email');
        return;
       }
      if(formState.password.length < 6){
        setError('Password is not valid');
        return;
       }

       const response = await httpLoginUser(formState);
       const data = await response.json();

       if (response.ok) {
           Auth.login(data.token);
        } else {
          setError(data.msg);
          return;
        }
        navigate('/user')
        setFormState({
            email: '',
            password: '',
      }); 
    };
    return ( 
       <AuthContainer>
        <AuthContainer2>
        <PictureSide className="boxShadow">
        <img src={AuthImage} alt="Yellow Helmets" />
        </PictureSide>
        <AuthenticationSide className="boxShadow">
            <form onSubmit={handleFormSubmit}>
             <h2>Login</h2>
            {/* Email */}
            <InputContainer placeholder="johndoe@yoda.com" label="Email" value={formState.email} name='email' onChange={handleChange}/>
            {/* Password */}
            <PasswordInput 
                label="Password" 
                span="Must be at least 6 characters" 
                handleChange={(e) => {
                    e.preventDefault();
                    setShowPass(prev => !prev)}}
                showPass={showPass}
                value={formState.password}
                name='password'
                onChange={handleChange}
            />
            {error && <p className="error">{error}</p> }  
            <PrimaryButton span="Login" />
            </form>
            <div className="outerSwitch">
            <div className="switchPage" style={{paddingInline: '1rem'}}>
            <p style={{fontWeight: "800"}}> Dont Have An Account?</p>
            <TertiaryButton functionName={() => setPage(false)} span="Sign Up" />
            </div>
            <div className="switchPage" style={{paddingInline: '1rem'}}>
            <p>Forgot your password?</p>
            <TertiaryButton functionName={forgotPassword} span="reset password" />
            </div>
            </div>
        </AuthenticationSide>
        </AuthContainer2>
       </AuthContainer>
     );
}
LoginComponent.propTypes = {
    setPage: PropTypes.func.isRequired,
};

export default LoginComponent;