import { UserSettingsMain, UserSettingsSection, InputSection, TitleSection, BottomButtonOuter } from "./userSettings.styles";
import { ButtonContainer } from "../../Quiz/quizStyles.styles";
import {useNavigate} from 'react-router-dom';
import InputDiv from "../../AdminRoutes/FormElements/InputText";
import Auth from '../../Helpers/auth';
import { useState, useEffect } from "react";
import PrimaryButton from "../../Components/Buttons/primaryButton.component";
import SecondaryButton from "../../Components/Buttons/secondaryButton.component";
import { httpDeleteUser, httpEditUser } from "../../Hooks/admin.hooks";

/// User settings page
const UserSettings = () => {
    const navigate = useNavigate();
    const {data} = Auth.getProfile();
    const [user, setUser] = useState(data);
    const [err, setErr] = useState('');

    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        }
    }, [navigate]);

    return ( 
       <UserSettingsMain>
        <h2>Update your profile</h2>
        <form>
        <UserSettingsSection>
            {/* For first name and last name */}
            <TitleSection>
            <h3>Name</h3>
            <span className="italicSpan">The name displayed here is what will appear as on your certificate</span>
            </TitleSection>
            <InputSection>
            <InputDiv title="First Name" value={user.firstName} name="firstName" nestedValue={setUser} upperValue={user}/>
            <InputDiv title="Last Name" value={user.lastName} name="lastName" nestedValue={setUser} upperValue={user}/>
            </InputSection>
        </UserSettingsSection>
        <UserSettingsSection>
            {/* For Email and password */}
            <TitleSection>
                <h3>Login Details</h3>
            </TitleSection>
            <InputSection>
            <InputDiv title="Email" value={user.email} name="email" nestedValue={setUser} upperValue={user}/>
            <InputDiv title="Password" value={user.password} name="password" nestedValue={setUser} upperValue={user}/>
            </InputSection>
        </UserSettingsSection>
        <UserSettingsSection>
             <TitleSection>
            <h4>Delete your account</h4>
            <span className="italicSpan">This action cannot be undone and is permanent</span>
            </TitleSection>
            <InputSection>
            <button onClick={async(e) => {
            e.preventDefault();
            let res = await httpDeleteUser(user?.id);
            let data = await res.json();
            console.log(res);
            if(res.ok){
                setErr('Your account has been deleted, you will now be logged out');
                setTimeout(() => {
                    Auth.logout();
                    navigate('/');
                }, 8000);
            } else {
                setErr(data.msg);
            }
        }} className="destructiveButton boxShadow"><span>Delete Account</span></button>
            </InputSection>
            {/* For Deleting the account */}
        </UserSettingsSection>
        <p>{err}</p>
        <BottomButtonOuter>
        {/* For the bottom 2 button */}
        <ButtonContainer>
        <SecondaryButton functionName={(e) => {
            e.preventDefault();
            navigate(`/user`)
        }} span="Home" />
        <PrimaryButton functionName={async(e) => {
            e.preventDefault();
            let res = await httpEditUser(user?.id, user);
            let data = await res.json();
            if(res.ok){
                setErr('Your account has been updated, reflections of change may take a moment to show')
            } else {
                setErr(data.msg);
            }
        }} span="Update Profile" />
        </ButtonContainer>
        </BottomButtonOuter>
        </form>

       </UserSettingsMain>
     );
}
 
export default UserSettings;