import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FormContainer } from "../../CreateRoutes/CreateForm.styles";
import Modal from "../../../Components/Modal/modal.component";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import FreeCourse from "./free-course.component";
import InputsForEditUser from "./FormEditUser/InputsEditUser.component";
import ButtonEditUser from "./FormEditUser/ButtonsEditUser.component";
import {AuthCheckAdmin} from '../../../Utils/AuthCheck/adminCheck';
import useUser from "../../../Utils/Users/singleUser/useUser";

// Form for editing a user
const EditUser = () => {
    const {id} = useParams();
    const navigate = useNavigate();
   // const {data} = Auth.getProfile();
    const [isOpen, setIsOpen] = useState(false);
    const [password, setPassword] = useState('');
    const { user, successState, setUser, setSuccessState} = useUser(id);
   
    return (
        <AuthCheckAdmin>
        <FormContainer>
        <h2>Edit User</h2>
        <hr />
        <form>
        {/* Inputs  */}
        <InputsForEditUser user={user} setUser={setUser} password={password} setPassword={setPassword} />
        {/* Free Course */}
         <FreeCourse id={user?._id}/>
         {/* Pop Up Modal */}
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        {successState && <p>{successState}</p>}
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        {/* Submit buttons */}
         <ButtonEditUser user={user} password={password} setIsOpen={setIsOpen} setSuccessState={setSuccessState}/>
        </form>
        </FormContainer>
        </AuthCheckAdmin>
      );
}
 
export default EditUser;