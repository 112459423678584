import InputDiv from "../../../FormElements/InputText";

const CompanyAdmin = ({title, user, setUser, type, setType}) => {

    const typeTemplate = (
        <div style={{marginBlock: '2rem'}}>
        <label htmlFor="type" style={{paddingRight: '2rem'}}>Type</label>
        <select name="type" id="type" value={type} onChange={(e) => setType(e.target.value)}>
        <option value="Manager">Manager</option>
        <option value="User">Employee</option>
         </select> 
        </div>
    );

    return (  
        <>
        {title ? <h2>{title}</h2> : null}
        <InputDiv title="First Name" value={user.firstName} name="firstName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Last Name" value={user.lastName} name="lastName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Email" value={user.email} name="email" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Password" value={user.password} name="password" nestedValue={setUser} upperValue={user} />
        {type ? typeTemplate : null}
        </>
     );
}
 
export default CompanyAdmin;