import UserCourses from "../UserHomepage/userCourses.component";
import { CourseContainer } from "../UserHomepage/userHome.styles";
import Auth from '../../Helpers/auth';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MobileCoursesContainer, BreadContainer } from "./MoblileCourses.styles";
import BreadCrumbs from "../../Components/Breadcrumbs/breadcrumbs.component";

// Home page for mobile
const MobileCourses = () => {
    const {data} = Auth.getProfile();
    const [chosen, setChosen] = useState('AllCourses');
    const navigate = useNavigate();
    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        }
    }, [navigate]);
    
    return ( 
        <MobileCoursesContainer>
            <BreadContainer>
                <BreadCrumbs one='Home' oneLink='/user' two='User Courses' />
             </BreadContainer>
        <h2>Your Courses</h2>
        <CourseContainer>
                    <UserCourses user={data} chosen={chosen}/>
        </CourseContainer>
        </MobileCoursesContainer>
     );
}
 
export default MobileCourses;