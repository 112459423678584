import Certificate from "../../Quiz/Certificate/certificate.component";
import { EachCourse } from "./CourseEntity.styles";
import {Link} from 'react-router-dom';

// Entity on user home page for showing that the course is complete
const CompletedCourse = ({courses, data}) => {

    return (  
        <div>
        {courses?.courses?.map((c) => {
         if(c.complete === true){
            return <EachCourse className="complete boxShadow" key={c.id}>
            <div className='top'>
            <Link className="title" to={`/course/review/${c.courseId}`}>{c?.courseName}</Link>
            <p>Completed On: <span>{c?.date}</span></p>
            <p>Expires On: <span>{c?.expiryDate}</span></p>
            </div>
            <div className='bottom'>
                <Certificate name={data?.firstName + ' '+ data?.lastName} course={c?.courseName} 
                dateCompleted={c?.date} expiryDate={c?.expiryDate} certNumber={c?.certNum}/>
            </div>
                </EachCourse>
         } else {
            return null;
         }
        })}
        </div>
    );
}
 
export default CompletedCourse;