import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import Loader from "../../../Components/Loader/loader.component";
import { useState } from "react";
import { editUser } from "../../../Utils/Users/editUsers/editUser";
import PropTypes from 'prop-types';

// Function for handling editing a user
const EditUserSubmit = ({user, password,setIsOpen, setSuccessState, type}) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        const editedUser = {
            ...user,
            password,
            ...(type && { type })
        };
        await editUser(editedUser, setSuccessState, setIsOpen, setLoading);
    }

    return (  
    <>
        {loading ? <Loader /> : <PrimaryButton functionName={(e) => handleSubmit(e)} span="Edit User" />}
    </>
    );
};

EditUserSubmit.propTypes = {
    user: PropTypes.object.isRequired,
    password: PropTypes.string.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    setSuccessState: PropTypes.func.isRequired,
    type: PropTypes.string,
};
 
export default EditUserSubmit;