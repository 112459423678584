import styled from 'styled-components';

export const UserSettingsMain = styled.main`
   // border: 1px solid orange;
    width: 80%;
    margin: 0 auto;
    padding-block: 2rem;
    @media screen and (width <=600px){
       width: 100%;
       padding: 1.5rem;
    }
`;

export const UserSettingsSection = styled.section`
   //border: 1px solid purple;
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-bottom: 2px solid ${({theme}) => theme.colors.grey4};
    @media screen and (width <=1000px){
       flex-direction: column;
    }
`;
export const TitleSection = styled.div`
    //border: 1px solid green;
    width: 30%;
    h3{
        font-weight: 900;
        padding-bottom: 0.3rem;
    }
    span{
        margin-bottom: 2rem;
    }
    @media screen and (width <=1000px){
       width: 100%;
       span{
        margin-bottom: 1rem;
       }
    }

`;

export const InputSection = styled.div`
    //border: 1px solid blue;
    width: 70%;
    label{
        font-weight: bold;
    }
    input{
        width: 70%;
    }
    @media screen and (width <=1000px){
       width: 100%;
       button{
        margin-bottom: 1rem;
       }
    }

`;

export const BottomButtonOuter = styled.section`
    margin-top: 2rem;  
`;