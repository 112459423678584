import styled from 'styled-components';

export const UnauthMain = styled.main`
    margin: 0 auto;
    border: 2px solid black;
    display: flex;
    background-color: ${({theme}) => theme.colors.grey2};
    div{
        border: 20px solid ${({theme}) => theme.colors.yellow5};
        margin: auto;
        border-radius: 5px;
        padding: 2rem;
        background-color: ${({theme}) => theme.colors.grey};
    }
    button{
        margin-top: 1rem;
    }
`;