import { useState } from "react";
import { useParams } from "react-router-dom";
import CompanyAdmin from "../../CreateRoutes/CreateCompany/CompanyAdmin/company-admin.component";
import { FormContainer } from "../../CreateRoutes/CreateForm.styles";
import DisableUserButton from "../EditUser/user-edit-disable.component";
import ActivateUserButton from "../EditUser/user-edit-activate.component";
import DeleteUserButton from "../EditUser/user-edit-delete.component";
import EditUserSubmit from "../EditUser/user-edit-submit.component";
import Modal from "../../../Components/Modal/modal.component";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { useNavigate } from "react-router-dom";
import useUser from "../../../Utils/Users/singleUser/useUser";

const EditManagerOrEmployee = () => {
    const navigate = useNavigate();
    const [type, setType] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const {userId} = useParams();

    const { user, successState, setUser, setSuccessState} = useUser(userId);
   

    return (
       <FormContainer>
       <CompanyAdmin title={`Edit ${user?.firstName} ${user?.lastName}`} user={user} setUser={setUser} setType={setType} type={type}/>
       <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <DeleteUserButton id={userId} setSuccessState={setSuccessState} setIsOpen={setIsOpen}/>
        {user?.active ? <DisableUserButton id={userId} setSuccessState={setSuccessState} setIsOpen={setIsOpen}/> : <ActivateUserButton id={userId} setSuccessState={setSuccessState} setIsOpen={setIsOpen}/>}
        <EditUserSubmit user={user} type={type} setSuccessState={setSuccessState} setIsOpen={setIsOpen}/>
       </div>
       {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        {successState && <p>{successState}</p>}
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
       </FormContainer>
      );
}
 
export default EditManagerOrEmployee;