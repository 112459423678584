import { AdminTabs } from "./admin.styles";
import PropTypes from 'prop-types';

// Tabs for selecting companies/courses/users on the admin homepage
const AdminTabsComponent = ({showCompanies, showCourses, setShowCompanies, setShowCourses, setShowUsers, showUsers}) => {

    const handleTabClick = (tab) => {
        setShowCompanies(tab === 'companies');
        setShowCourses(tab === 'courses');
        setShowUsers(tab === 'users');
    };

    return (  
        <AdminTabs>
        {/* Companies */}
        <button className={showCompanies ? "tabButtonActive boxShadow" : "tabButton boxShadow"} onClick={(e) => {
            e.preventDefault();
            handleTabClick('companies');
        }}><span>companies</span></button>
        {/* Users */}
        <button className={showUsers ? "tabButtonActive boxShadow" : "tabButton boxShadow"} onClick={(e) => {
            e.preventDefault();
            handleTabClick('users');
        }}><span>users</span></button>
        {/* Courses */}
        <button className={showCourses ? "tabButtonActive boxShadow" : "tabButton boxShadow"} onClick={(e) => {
            e.preventDefault();
            handleTabClick('courses');
        }}><span>courses</span></button>
        </AdminTabs>
    );
};

AdminTabsComponent.propTypes = {
    showCompanies: PropTypes.bool.isRequired,
    showCourses: PropTypes.bool.isRequired,
    setShowCompanies: PropTypes.func.isRequired,
    setShowCourses: PropTypes.func.isRequired,
    setShowUsers: PropTypes.func.isRequired,
    showUsers: PropTypes.bool.isRequired,
};
 
export default AdminTabsComponent;