import styled from "styled-components";

export const OnlineCoursesContainer = styled.main`
    display: flex;
    flex-direction: column;
    width: 90%;
    /* border: 1px solid orange;  */
    @media screen and (width <= 600px){
        width: 95%;
    }
`;

export const SearchContainer = styled.aside`
    width: 100%;
    margin-block: 1rem;
    display: flex;
    //border: 1px solid blue;
    justify-content: space-between;
    align-items: center;
    input{
        margin-right: 1rem;
        width: 200px;
    }
    @media screen and (width <= 700px){
        flex-direction: column;
    }
    @media screen and (width <= 600px){
        align-items: start;
        padding-left: 1rem;
    }
`;

export const SearchBarContainer = styled.div`
 @media screen and (width <= 600px){
        
 }
`;


export const FilterButton = styled.aside`
    visibility: hidden;
    display: none;
    @media screen and (width <= 1100px){
    visibility: visible;
    display: flex;
    }
    @media screen and (width <= 700px){
    }
`;

export const TagContainer = styled.aside`
    width: 30%;
    border-radius: 5px;
    /* border: 1px solid orange; */
    padding-block: 1rem;
    background-color: hsla(0, 2.0408163265306043%, 90.3921568627451%, 0.3);
    @media screen and (width <= 1100px){
    visibility: hidden;
    width: 0%; 
    }
`;

export const CourseContainer = styled.section`
    /* border: 1px solid black; */
    width: 70%;
    height: 80vh;
    overflow-y: scroll;
    @media screen and (width <= 1100px){
        width: 100%;
    }
    @media screen and (width <= 600px){
        width: 100%;
        height: 85vh;
    }

`;

export const CourseCardComponent = styled.div`
    display: flex;
    border-radius: 5px;
    background-color: ${({theme}) => theme.colors.offWhite};
    margin-bottom: 1rem;
    margin-inline: 1rem;
    /* border: 1px solid orange; */
    @media screen and (width <= 900px){
    height: 200px;
    }
    @media screen and (width <= 600px){
     height: auto;
    }
`;

export const CourseImage = styled.div`
    margin-right: 1rem;
    /* border: 1px solid orange; */
    max-width: 30%;
    img{
        max-width: 100%;
        //max-height: 100%;
        height: auto;
        /* object-fit: contain; */
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        /* border: 1px solid green; */
    }
    @media screen and (width <= 900px){
       margin-right: 0;
       
    }
    @media screen and (width <= 600px){
        img{
          display: none;
        }
    }
`
export const CourseInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
   // gap: 0.6rem;
    overflow-wrap: normal;
    padding-top: 1rem;
    max-width: 70%;
   
    /* border: 1px solid green; */
    .topContainer{
        height: 70%;
    }
    .buttonContainer{
        height: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .buttonContainer button{
        padding-right: 1rem;
        margin: 0;
        text-align: right;
    }
    h4{
        font-weight: 700;
        color: ${({theme}) => theme.colors.grey5};
        padding-bottom: 0.5rem;
    }
    p{
        font-weight: 400;
        color: ${({theme}) => theme.colors.grey4};
    }
    @media screen and (width <= 700px){
        gap: 0.1rem;
        padding-left: 0.5rem;
        .buttonContainer{
            padding-bottom: 0.5rem;
        }
        
    }
`;
