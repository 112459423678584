import styled from 'styled-components';

export const FormContainer = styled.main`
    display: flex;
    flex-direction: column;
    width: 80%;
    overflow-y: scroll;
    /* border: 2px solid orange; */
    margin: 0 auto;
    margin-top: 1rem;
    img{
        max-width: 25%;
        max-height: 25%;
    }
    p{
        margin-bottom: 1rem;
        font-weight: bold;
    }
    span{
        font-weight: normal;
    }
`;

export const SubmitContainer = styled.section`
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 2rem;
    gap: 4rem;
`;
    
export const MappedResults = styled.section`
  /* border: 1px solid orange;   */
  margin-top: 2rem;
  h4{
   // color: pink;
   border-bottom: 2px solid  ${({theme}) => theme.colors.grey3};
  }
  li{
    /* border: 1px solid green; */
    background-color: ${({theme}) => theme.colors.grey1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding: 0.3rem;
    border-radius: 5px;
  }
  p{
    color : ${({theme}) => theme.colors.grey4};
  }
`;

export const CreateFormContainer = styled.section`
   background-color: ${({theme}) => theme.colors.grey1};
   padding: 1rem;
   margin-top: 1rem;
   border-radius: 5px;
   box-shadow: 0 0 5px ${({theme}) => theme.colors.grey3};

`;