import { httpDeleteCompany } from "../../../Hooks/admin.hooks";

export const deleteCompany = async (id, setSuccessState, setIsOpen, setLoading) => {
  setLoading(true);
  try {
    const res = await httpDeleteCompany(id);
    const data = await res.json();
    if (res.ok) {
      setSuccessState("Company has been activated");
    } else {
      setSuccessState(data.msg);
    }
  } catch (error) {
    setSuccessState("An error occurred while activating the company");
    console.error("Error activating company:", error);
  } finally {
    setIsOpen(true);
    setLoading(false);
  }
};
