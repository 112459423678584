import styled from 'styled-components';

export const EachCourse = styled.div`
    margin-top: 1rem;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: ${({theme})=> theme.colors.offWhite};
    //border: 1px solid green;
    .top{
        display: flex;
        flex-direction: column;
    }
    .bottom{
        display: flex;
        justify-content: end;
    }
    .title{
        font-weight: 700;
        font-size: 1.6rem;
        letter-spacing: normal;
        color: ${({theme})=> theme.colors.grey5};
        text-transform: capitalize;
    }
    .title:hover{
        color: ${({theme})=> theme.colors.orange5};
    }
    button{
        width: auto;
        max-width: 100%;
        //margin: 0 0.5rem 0.5rem 00;
    }
    a{
        padding-bottom: 0.5rem;
    }
    p span{
        font-style: italic;
        color: ${({theme})=> theme.colors.grey3};
    }
    @media screen and (width <= 600px){
     .bottom{
        margin-top: 1rem;
    }
    }

`;