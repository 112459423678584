//import Auth from "../../Helpers/auth";
import { ButtonContainer } from "../Completed/quiz-completed.styles";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../Components/Buttons/primaryButton.component";
import SecondaryButton from "../../Components/Buttons/secondaryButton.component";

// Page from when the user has no completed the course
const UserIncompleteComponent = ({id}) => {
    //const { data } = Auth.getProfile();
    const navigate = useNavigate();
    return ( 
        <>
        <h2>Sorry, You have not completed this course</h2>
            <ButtonContainer>
                <SecondaryButton functionName={(e) => {
                    e.preventDefault();
                    navigate('/user')
                }} span="Go Home"/>
                <PrimaryButton functionName={(e) => {
                    e.preventDefault();
                    navigate(`/course/quiz/${id}`)
                }} span="Retake Quiz" />
            </ButtonContainer>
        </>
    );
}
 
export default UserIncompleteComponent;