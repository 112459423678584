import { httpGetCompanyEmployees } from "../../../../Hooks/companies.hooks";

export const fetchEmployees = async (id, setEmployees, setSuccessState) => {
    try {
        const found = await httpGetCompanyEmployees(id);
        if (found) {
            setEmployees(found);
        } else {
            setSuccessState('No employees found');
        }
    } catch (error) {
        setSuccessState('An error occurred while fetching the employees');
        console.error('Error fetching employees:', error);
    }
};