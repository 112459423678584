import { useState, useEffect } from "react";
import { fetchCourses } from "./getCourses";

const useCourses = (id) => {
    const [courses, setCourses] = useState([]);
    const [successState, setSuccessState] = useState('');

    useEffect(() => {
        fetchCourses(id, setCourses, setSuccessState);
    }, [id]);

    return { courses, successState, setCourses, setSuccessState};
};

export default useCourses;