import { FormContainer, MappedResults, CreateFormContainer } from "../../CreateRoutes/CreateForm.styles";
import { useParams } from "react-router-dom";
import CreateManager from "./create-manager.component"; 
import MappedUsersOrEmployee from "./mapped-managers-users.component";
import useManagers from "../../../Utils/Companies/Managers-Employees/allManagers/useManagers";
import useEmployees from "../../../Utils/Companies/Managers-Employees/allEmployees/useEmployees";

const EditManagersAndUsers = () => {

    const {id} = useParams();
    // successState, setManagers, setSuccessState - from useManagers
    const { managers } = useManagers(id);
    // setEmployees, success, setSuccess - from useEmployees
    const { employees } = useEmployees(id);

    return (  
      <FormContainer>
        <h2>Edit Managers and Employees</h2>
        <hr />
        <CreateFormContainer>
        <CreateManager />
        </CreateFormContainer>
        <MappedResults>
        <MappedUsersOrEmployee user={managers} title="Company Managers"/>
        </MappedResults>
        <MappedResults>
        <MappedUsersOrEmployee user={employees} title="Company Employees" />
        </MappedResults>
      </FormContainer>
    );
}
 
export default EditManagersAndUsers;