import { httpGetUser } from "../../../Hooks/users.hooks";

export const fetchUser = async(id, setUser, setSuccess) => {
    try {
        const foundUser = await httpGetUser(id);
        if (foundUser) {
            setUser(foundUser[0]);
        } else {
            setSuccess('No user found');
        }
    } catch (error) {
        setSuccess('An error occurred while fetching the user');
        console.error('Error fetching user:', error);
    }
}