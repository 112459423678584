import { useState } from "react";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import Loader from "../../../Components/Loader/loader.component";
import { AuthCheckAdmin } from "../../../Utils/AuthCheck/adminCheck";
// import FreeCourseComponent from "../../../Components/Courses/freeCourse.component"; 
import useCourses from '../../../Utils/Courses/allCourses/useCourses';
import {freeCourse} from '../../../Utils/Courses/buyCourse/freeCourse/purchaseFreeCourse';
// Buy a course for the user - no payment
const FreeCourse = ({id}) => {
    const [selectedCourse, setSelectedCourse] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);

    //successState, setCourses, setSuccessState
   const { courses } = useCourses();

    const purchaseCourse = async() => {
        setLoading(true);
        if(status.length){
            setStatus('');
        }
        const course = {courseId: selectedCourse, userId: id}
        await freeCourse(course, setStatus, setLoading);
    }
    
    return (  
        <AuthCheckAdmin>
        {/* <FreeCourseComponent courses={courses} selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} loading={loading} purchaseCourse={purchaseCourse} status={status} /> */}
        <div style={{backgroundColor: 'lightGrey', marginTop: '1rem', padding: '1rem', borderRadius: '5px'}}>
        <h4>Buy a course - no payment required</h4>
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap:'2rem'}}>
        <label htmlFor="courses">Courses</label>
        <select id="courses" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)}>
        <option value="">Select Course</option>
        {courses?.map((course) => {
            return <option key={course?._id} value={course?._id}>{course?.courseName}</option>
        })}
        </select>
        </div>
        {loading ? <Loader /> : <PrimaryButton functionName={purchaseCourse} span="Buy Course" />}
        {status && <p>{status}</p>} 
        </div>
        </AuthCheckAdmin>
    );
}
 
export default FreeCourse;