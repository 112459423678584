import { TopRightContainer, EachLink,  MobileLinksContainer, UserInfo, UserLinks, CourseContainer, OuterContainer, LeftContainer, RightContainer } from './userHome.styles';
import Auth from '../../Helpers/auth';
import { useState, useEffect } from 'react';
import icon from '../../Visuals/Images/Default.jpg';
import { Link, useNavigate } from "react-router-dom";
import UserCourses from './userCourses.component';

// The base page for the users home - shows 
const UserHome = () => {
    const {data} = Auth.getProfile();
    const url = `/user/settings/${data.id}`;
    const coursesUrl = `/user/user-courses/${data.id}`;

    const navigate = useNavigate();
    
    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        }
    }, [navigate]);
    
    const [showAllCourses, setAllCourses] = useState(true);
    const [showCompleteCourses, setShowCompleteCourses] = useState(false);
    const [showIncompleteCourses, setShowIncompleteCourses] = useState(false);
    const [chosen, setChosen] = useState('AllCourses');
    

    return ( 
        <OuterContainer>
        {Auth.loggedIn() ?
             <>
              <LeftContainer>
                <UserInfo>
                <img src={icon} alt="user" />
                <h3>{data?.firstName} {data?.lastName}</h3>
                </UserInfo>
                <UserLinks>
                <Link to={`/online`}>Buy Courses</Link>
                <Link to={url}>Settings</Link>
                </UserLinks>
                  </LeftContainer>
                  <RightContainer>
                  <TopRightContainer>
                    <button className={showAllCourses ? 'boxShadow tabButtonActive': 'boxShadow tabButton'}
                    onClick={(e) => {
                      e.preventDefault();
                      setAllCourses(true);
                      setShowIncompleteCourses(false);
                      setShowCompleteCourses(false);
                      setChosen('AllCourses')
                    }}
                    ><span>All Courses</span></button>
                    <button className={showIncompleteCourses ? 'boxShadow tabButtonActive': 'boxShadow tabButton'}
                     onClick={(e) => {
                      e.preventDefault();
                      setAllCourses(false);
                      setShowIncompleteCourses(true);
                      setShowCompleteCourses(false);
                      setChosen('IncompleteCourses');
                    }}
                    ><span>Incomplete Courses</span></button>
                    <button className={showCompleteCourses ? 'boxShadow tabButtonActive': 'boxShadow tabButton'}
                     onClick={(e) => {
                      e.preventDefault();
                      setAllCourses(false);
                      setShowIncompleteCourses(false);
                      setShowCompleteCourses(true);
                      setChosen('CompleteCourses');
                    }}
                    ><span>Complete Courses</span></button>
                  </TopRightContainer>
                  {/* Displays the users courses that are chosen */}
                  <CourseContainer>
                    <UserCourses user={data} chosen={chosen}/>
                  </CourseContainer>
              </RightContainer>
              {/* MOBILE: LINKS */}
              <MobileLinksContainer>
               <EachLink className='boxShadowHover'>
                <Link to={coursesUrl}>Your Courses</Link>
                </EachLink>
                <EachLink className='boxShadowHover'>
                <Link to={`/online`}>Buy Courses</Link>
                </EachLink>
                <EachLink className='boxShadowHover'>
                <Link to={url}>Settings</Link>
                </EachLink>
              </MobileLinksContainer>
              </>
           :
         (  <p>You are not logged in</p>)
    }
        </OuterContainer>
     );
}
 
export default UserHome;