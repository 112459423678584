import { InputContainer } from "./FormElements.styles";

// Textarea Component: title, placeholder, value, setValue
const TextareaDiv = (props) => {
    
    return ( 
        <InputContainer>
            <label>{props?.title}</label>
            <textarea type="text" placeholder={props?.placeholder} value={props?.value} onChange={(e) => props.setValue(e.target.value)}/>
        </InputContainer>
     );
}
 
export default TextareaDiv;