import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Auth from "../../Helpers/auth";

export const AuthCheckAdmin = ({children}) => {

    const navigate  = useNavigate();
    const {data} = Auth.getProfile();

    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        } else if (data.type !== 'Admin'){
            navigate('/unauthorized')
        }
    }, [navigate, data]);

    return children;
}
