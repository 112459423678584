import { EachInput } from "../auth.styles";

// Password input for login
const PasswordInput = ({label, span, handleChange, showPass, value, name, onChange }) => {
    return ( 
        <EachInput>
            <div>
            <label>{label}</label>
            <span className="italicSpan" style={{paddingLeft: '1rem'}}>{span}</span>
            <br />
            <span className="passwordSwitch" onClick={handleChange}>{!showPass ? "Show password" : "Hide password"}</span>
            </div>
            <input type={!showPass ? "password" : "text"} value={value} name={name} onChange={onChange} required/>
        </EachInput>
     );
}
 
export default PasswordInput;