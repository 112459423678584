import Auth from '../Helpers/auth';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = Auth.getToken();


// GET: All the courses - Not a protected route
async function httpGetAllCourses(){
    try{
       let courses = await fetch(`${API_URL}/courses/courses`);
        return courses.json();
    } catch(err){
        return err;
    }
}

// GET: Fetches courses that are filtered by a tag - Not a protected route
async function httpFilterByTag(tag){
    try{
        let courses = await fetch(`${API_URL}/courses/filter-tag/${tag}`);
         return courses.json();
     } catch(err){
         return err;
     }
}

// GET: Fetches courses that are filtered by a search - Not a protected route
async function httpFilterBySearch(search){
    try{
        let courses = await fetch(`${API_URL}/courses/filter-search/${search}`);
         return courses.json();
     } catch(err){
         return err;
     }
}

// GET: Gets a single course - Not a protected route
async function httpGetSingleCourse(id){
    try{
        let courses = await fetch(`${API_URL}/courses/${id}`);
        return courses.json();
     } catch(err){
         return err;
     }
}

// PUT: Buys a course - Protected route
async function httpBuyCourse(data){
         return await fetch(`${API_URL}/courses/buy`, {
            method: 'put',
            headers: {
                "Content-Type": "application/json",
                'Authorization': token ? `Bearer ${token}` : ''
            },
             body: JSON.stringify(data)
        });
}

export {
    httpGetAllCourses,
    httpFilterByTag,
    httpFilterBySearch,
    httpGetSingleCourse,
    httpBuyCourse
}