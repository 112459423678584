import styled from 'styled-components';

export const CourseHomepageContainer = styled.main`
    display: flex;
    @media screen and (width <= 1500px){
        flex-direction: column-reverse;
        margin: 0 auto;
    }
`;

export const CouseHomeLeft = styled.section`
   // border: 1px solid blue;
    width: 70%;
    padding: 1rem;
    overflow-y: scroll;
    background-color: ${({theme}) => theme.colors.offWhite};
    h1{
        margin-bottom: 1rem;
        color: ${({theme}) => theme.colors.grey7};
        border-bottom: 3px solid ${({theme}) => theme.colors.orange2};
        //text-transform: uppercase;
    }
    
    @media screen and (width <= 1500px){
       // border: 2px solid green;
        width: 100%;
        height: 100vh;
    }
`;


export const CourseHomeRight = styled.aside`
   // border: 1px solid orange;
    width: 30%;
    padding: 1rem;
    background-color: ${({theme}) => theme.colors.grey6};
    @media screen and (width <= 1500px){
       width: 100%;
       display: flex;
       justify-content: center;
       background-color: ${({theme}) => theme.colors.grey6};
    }
    button{
        margin-left: 1rem;
        border: 1px solid orange;
        margin-top: 1rem;
        width: 200px;
    }
`;
export const BreadcrumbsContainer = styled.div`
@media screen and (width <= 1500px){
       display: none;
    }
`;

export const LinksContainer = styled.div`
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
   // margin-top: 1rem;
    .courseStatus{
        color: ${({theme}) => theme.colors.orange4};
        margin-bottom: 1rem;
        font-style: italic;
    }
    @media screen and (width <= 1500px){
       display: none;
    }
    
`;

export const PresentationContainer = styled.div`
    width: calc(100% - 30px);
    //height: calc(100% - 30px);
    height: 600px;

    margin: 0 auto;
    margin-top: 1rem;
    border: 2px solid ${({theme}) => theme.colors.grey4};
    @media screen and (width <= 600px){
        border: 2px solid purple;
        //width: 900px;
        height: 400px;
    }
`;


export const EachSection = styled.div`
   // border: 1px solid pink;
    height: auto;
    margin-block: 2rem;
    ul{
        margin-left: 4rem;
    }
    a{
        color: ${({theme}) => theme.colors.orange6};
    }
    li{
        margin-top: 0.5rem;
    }
    a:visited{
        color: ${({theme}) => theme.colors.grey2};
    }
`
export const SubsectionContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    //border: 2px solid green;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey1};
    h4{
        margin-top: 0.5rem;
    }

`;