import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import Loader from "../../../Components/Loader/loader.component";
import { httpCreateCourse, httpImgAndPdf } from "../../../Hooks/admin.hooks";
import { useState } from "react";

// Submit Function for Handling the course Submit
const CreateCourseSubmit = ({questions, pdf, notes, videoLinks, documentation, 
    image, expiryDate, certificatePrefix, courseName, tags, description, price, setSuccessState, setIsOpen}) => {
   
    const [loading, setLoading] = useState(false);
    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        // First send the main course details
        const fullCourse = Object.assign({}, {
            courseName: courseName,
            price: price,
            description: description,
            tags: tags,
            notes: notes,
            videoLinks: videoLinks,
            documentation: documentation,
            quiz: questions,
            expiryDate: expiryDate,
            certificatePrefix: certificatePrefix
        })
        
        let res = await httpCreateCourse(fullCourse);
        let data = await res.json();
        // Then send over the pdf and images
        if (res.ok) {       
            const formData = new FormData();
            formData.append('pdf', pdf);
            formData.append('image', image);
            formData.append('courseName', courseName);
            let response = await httpImgAndPdf(formData);
            if(response.ok){
                setSuccessState('Course has been created');
                setIsOpen(true);
                setLoading(false);
            } else {
                setSuccessState('An issue occured with uploading pdfs and images');
                setIsOpen(true);
                setLoading(false);
            }
           } else {
            setSuccessState(data.msg);
            setIsOpen(true);
            setLoading(false);
        }
    }
    return (
     <>
     {loading ? <Loader /> : <PrimaryButton functionName={(e) => handleSubmit(e)} span="Create Course" /> }
     </> 
    );
}
 
export default CreateCourseSubmit;