import Logo from '../../../Visuals/Images/Logo.png';
import { FooterDiv, LeftFooter, RightFooter, BottomDiv, OuterDiv } from './footer.styles';
//import { useState } from 'react';
const Footer = () => {
    // const [email, setEmail] = useState('');
    // const signUp = (e) => {
    //     e.preventDefault();
    //     // Send the email to the mailchimp thing
    // }
    // The email portion needs to be set up 
    return ( 
        <FooterDiv>
            <OuterDiv>
            <LeftFooter>
            <img src={Logo} alt="Yoda Safety Services Logo" />
            <p>Owned & Operated by Jim Benoit</p>
            <span>Registered under the Nova Scotia Workers Compensation Board to Safety Certified Companies</span>
            {/* <div>
                <p>Sign up for monthly emails</p>
                <div>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <button className='primaryButton boxShadow' onClick={(e) => signUp(e)}>
                    <span>Sign Up</span>
                </button>
                </div>
            </div> */}
            </LeftFooter>
            {/* <RightFooter>
            {(process.env.REACT_APP_PRODUCTION === undefined || process.env.REACT_APP_PRODUCTION === 'false') && 
            <>
            // CHANGE THE A to LINK
            <a href="/">About us</a>
            <br />
            <a href="programDevelopment">Health & Safety Program Development</a>
            <br />
            <a href="wcbCertified">Approved By NS WCB Safety Certified</a>
            <br />
            <a href="insurance">Fully Insured</a>
            <br />
            <a href="resources">Resources</a>
            <br />
            <a href="onlineTraining">Online Training</a>
            <br />
            <a href="classroomTraining">Classroom Training</a>
            <br />
            <a href="contactUs">Contact Us</a>
            </>
            }     
            </RightFooter> */}
            </OuterDiv>
            {/* <BottomDiv>
            {/* <hr /> */}
           
            {/* </BottomDiv> */}
        </FooterDiv>
     );
}
 
export default Footer;