import { Outlet } from "react-router-dom";

const CompanyBase = () => {
    return (
        <>
        <Outlet />
        </>
      );
}
 
export default CompanyBase;