import styled from 'styled-components';

export const OuterBurgerDiv = styled.div`

`;

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: ${({theme}) => theme.colors.black};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  width: 40%;
  z-index: 20;
  .topNav{
    //border: 1px solid red;
    position: absolute;
    top: 15px;
    left: 15px;
  }
  .bottomNav{
    //border: 1px solid blue;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    position: absolute;
    top: 20%;
    width: 90%;


  }
  a {
    transition: color 0.3s linear;
    @media (max-width: 576px) {
      //text-align: center;
    }
    &:hover {
      color: ${({theme}) => theme.colors.yellow4};
    } 
  }
  @media screen and (width <= 1100px){
    width: 60%;
  }
  @media screen and (width <= 600px){
    width: 100%;
  }
`