import styled from 'styled-components';
// For the main question page
export const QuizContainer = styled.main`
    width: 80%;
    height: 100vh;
    overflow-y: scroll;
    margin: 0 auto;
    h1{
        color: ${({theme}) => theme.colors.grey5};
        margin-block: 0.5rem;
        border-bottom: 2px solid ${({theme}) => theme.colors.orange1};
        font-weight: 800;
    }
    @media screen and (width <= 1000px){
        width: 80%;
    }
    @media screen and (width <= 600px){
        width: 95%;
    }
`;
//For the bottom button
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 2rem;
    button{
        min-width: 200px;
        margin-block: 2rem;
    }
    @media screen and (width <= 600px){
        width: 100%;
        flex-direction: column-reverse;
        gap: 0.5rem;
        button{
            min-width: 100%;
            margin-block: 0.5rem;
        }
    }
`;

// For Each Question
export const EachQuestionContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-block: 1.5rem;
    padding: 1rem;
    /* padding-top: 0.5rem;
    padding-left: 1rem; */
    border-radius: 5px;
    padding-bottom: 1rem;
    //border: 20px solid ${({theme}) => theme.colors.grey};
    background-color:  ${({theme}) => theme.colors.offWhite};
    h3{
        font-weight: 500;
        color: ${({theme}) => theme.colors.grey5};
        margin-bottom:1rem;
        padding-left: 2rem;
    }
    .answersContainer{
        border: 1px solid orange;
        gap: 1rem;
    }
`;


export const EachAnswer = styled.div`
    display: flex;
    width: 80%;
    padding: 0.2rem;
    margin-left: 2rem;
    margin-top: 0.5rem;
    label{
        margin-left: 1rem;
        padding-left: 0.5rem;
    }
    border: 1.5px solid ${({theme}) => theme.colors.grey2};
    border-radius: 5px;
    padding-left: 1rem;
`;

export const IncompleteContainer = styled.main`
    

`;