import { useState, useCallback } from "react";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import SearchInput from "../searchInput.component";
import { InsideSearch } from "../admin.styles";
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';


const SearchCompanies = ({companies, setFiltered, setErr}) => {
    const [search, setSearch] = useState('');

    const handleSearchChange = (val) => {
        setSearch(val);
        debouncedSearch(val);
    };

    const debouncedSearch = useCallback(
        debounce((value) => {
            setErr('');
            const searchResults = companies.filter(c => c.companyName.toLowerCase().includes(value.toLowerCase()));
            if (!searchResults.length) {
                setErr('No companies found');
            }
            setFiltered(searchResults);
        }, 300),
        [companies, setFiltered, setErr]
    );

    const setSearchCompanies = (e) => {
        e.preventDefault();
        debouncedSearch(search);
    };
    return ( 
        <InsideSearch>
        <SearchInput value={search} name="companies" setValue={handleSearchChange} />
        <PrimaryButton functionName={setSearchCompanies} span="search"/>
        </InsideSearch>
     );
};

SearchCompanies.propTypes = {
    companies: PropTypes.arrayOf(PropTypes.shape({
        companyName: PropTypes.string.isRequired,
    })).isRequired,
    setFiltered: PropTypes.func.isRequired,
    setErr: PropTypes.func.isRequired,
};

export default SearchCompanies;