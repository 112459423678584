import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { useState } from "react";
import Loader from "../../../Components/Loader/loader.component";
import PropTypes from 'prop-types';
import {editCompany} from '../../../Utils/Companies/editCompany/editCompany';

// Function for editing a company
const SubmitEditCompany = ({user, company, setSuccessState, setIsOpen, logo}) => {
    const [loading, setLoading] = useState(false);

    const editCompanySubmit = async(e) => {
        e.preventDefault();
        await editCompany(e, company, setSuccessState, setIsOpen, setLoading, user, logo);
    }
    return (  
        <>
        {loading ? <Loader /> : <PrimaryButton functionName={editCompanySubmit} span="Edit Company" />}
        </>
    );
};

SubmitEditCompany.propTypes = {
    user: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    setSuccessState: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    logo: PropTypes.object,
};
 
export default SubmitEditCompany;