import { useState, useEffect} from "react";
import { httpGetSingleCourse } from "../Hooks/courses.hooks";
import { useParams, useNavigate } from "react-router-dom";
import EachQuestion from "./EachQuestion/eachQuestion.component";
import { QuizContainer, ButtonContainer } from "./quizStyles.styles";
import QuizSubmit from "./quizSubmit.component";
import { useQuery } from "@tanstack/react-query";
import BreadCrumbs from "../Components/Breadcrumbs/breadcrumbs.component";
import Auth from '../Helpers/auth';

// Component for doing the quiz
const QuizComponent = () => {
    const {id} = useParams();

    const navigate = useNavigate();
    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        }
    }, [navigate]);

     const [correct, setCorrect] = useState(0);

    const {data, error, isLoading} = useQuery({
        queryKey: ['userId', id],
        queryFn: () =>  httpGetSingleCourse(id),
        enabled: !!id,
        retry: 5
    });
    const quizLength = data?.quiz.length;

    if(isLoading){
        return <h3>Loading...</h3>
    }
    if(error){
        return <h3>An error has occured</h3>
    }

    return (  
        <QuizContainer>
            <BreadCrumbs one={data?.courseName} oneLink={`/course/home/${data?.courseName}`} two="Quiz" />
            <h2>{data?.courseName} Quiz</h2>
            <span className="italicSpan">You must have a score of 60% or higher to pass this course</span>
                {data.quiz?.map((q, i) => {
                   return <EachQuestion key={q.id} i={i} q={q} setCorrect={setCorrect} correct={correct}/>
                })}
                <ButtonContainer>
                    <QuizSubmit id={data?._id} correct={correct} length={quizLength} courseName={data?.courseName} course={data}/>
                </ButtonContainer>
        </QuizContainer>
    );
}
 
export default QuizComponent;