import { SubmitContainer } from "../../../CreateRoutes/CreateForm.styles";
import DeleteCompanyButton from "../company-edit-delete.component";
import DeactivateCompanyButton from "../company-edit.deactivate.component";
import ActivateCompanyButton from "../company-edit-active.component";
import SubmitEditCompany from "../company-edit-submit.component";
import TertiaryButton from "../../../../Components/Buttons/tertiaryButton.component";
import PropTypes from 'prop-types';

const EditFormButtons = ({company, gotoManagers, setIsOpen, setSuccessState, logo}) => {
    return ( 
        <>
         <SubmitContainer>
        <DeleteCompanyButton id={company?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        {company?.active ? <DeactivateCompanyButton id={company?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState}/>
        :
        <ActivateCompanyButton id={company?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState}/>
        }
        <SubmitEditCompany company={company} setIsOpen={setIsOpen} setSuccessState={setSuccessState} logo={logo}/>
        </SubmitContainer>
        
        <div style={{marginBlock: '2rem'}}>
        <TertiaryButton functionName={gotoManagers} span="Edit Managers and Employees" />            
        </div>
        </>
     );
};

EditFormButtons.propTypes = {
    company: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
    }).isRequired,
    gotoManagers: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    setSuccessState: PropTypes.func.isRequired,
    logo: PropTypes.object,
};
 
export default EditFormButtons;