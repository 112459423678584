import CompletedCourse from "./CompleteCourse.component";
import IncompleteCourse from "./IncompleteCourse.component";

// Shows complete and incomplete courses on the users home page
const AllCourses = ({courses, data}) => {
    return ( 
        <div>
            <IncompleteCourse courses={courses}/>
            <CompletedCourse courses={courses} data={data} />
        </div>
     );
}
 
export default AllCourses;
