import { SubmitContainer } from "../../../CreateRoutes/CreateForm.styles";
import EditUserSubmit from '../user-edit-submit.component';
import DisableUserButton from "../user-edit-disable.component";
import DeleteUserButton from "../user-edit-delete.component";
import ActivateUserButton from "../user-edit-activate.component";
import PropTypes from 'prop-types';

const ButtonEditUser = ({user, password, setIsOpen, setSuccessState}) => {
    return ( 
        <>
         <SubmitContainer>
        <div style={{display: 'flex', flexDirection: 'row-reverse', alignContent: 'center', justifyContent: 'space-evenly', width: '100%'}}>
        <EditUserSubmit user={user} password={password} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        {user?.active ? 
        <DisableUserButton id={user?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        :
        <ActivateUserButton id={user?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        }
        <DeleteUserButton id={user?._id} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        </div>
        </SubmitContainer>
        </>
     );
};

ButtonEditUser.propTypes = {
    user: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
    }).isRequired,
    password: PropTypes.string.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    setSuccessState: PropTypes.func.isRequired,
};
 
export default ButtonEditUser;