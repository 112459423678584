import styled from "styled-components";

export const BreadcrumbContainer = styled.div`
    display: flex;
    width: auto;
    margin-top: 0.5rem;
    gap: 0.5rem;
    /* border: 1px solid orange; */
   // margin-left: 1rem;
    margin-bottom: 0.5rem;
    
    .primaryArrow{
        fill: none;
    }
    .secondaryArrow{
        fill: ${({theme}) => theme.colors.orange3};
    }
`;

export const EachBreadcrumb = styled.div`
    display: flex;
    align-items: center;
    span{
        text-transform: capitalize;
        color: ${({theme}) => theme.colors.grey3};
    }
`;