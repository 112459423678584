import PrimaryButton from '../../../Components/Buttons/primaryButton.component';
import NotFoundImg from '../../../Visuals/Images/NotFound.svg';
import { NotFoundContainer } from './notFound.styles';
import  { useNavigate} from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
    return (
        <NotFoundContainer>
        <img src={NotFoundImg} alt='404 illustration' />
        <div>
        <h2>Sorry, this page is gone</h2>
        <PrimaryButton functionName={() => navigate('/')} span="Return Home"/>
        </div>
        </NotFoundContainer>
      );
}
 
export default NotFound;