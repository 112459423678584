import { httpDisableUser } from '../../../Hooks/users.hooks';

export const deactivateUser = async (id, setSuccessState, setIsOpen, setLoading) => {
    setLoading(true);
    try {
        const res = await httpDisableUser(id);
        const data = await res.json();
        if (res.ok) {
            setSuccessState('User has been disabled');
        } else {
            setSuccessState(data.msg);
        }
    } catch (error) {
        setSuccessState('An error occurred while disabling the user');
        console.error('Error deactivating user:', error);
    } finally {
        setIsOpen(true);
        setLoading(false);
    }
};