import styled from "styled-components";

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    background-color: black;
    .topNav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 1rem;
        padding-top: 1rem;
        /* border: 1px solid orange; */
        
    }
    .bottomNav{
        padding-block: 0.5rem;
    }
    .links{
        display: flex;
        justify-content: space-evenly;
        gap: 1rem;
        width: 100%;
        /* border: 1px solid green; */
    }
    .links a:hover{
        color: ${({theme}) => theme.colors.yellow3};
    }
    .links a:active{
        color: ${({theme}) => theme.colors.orange4};
    }
    .first{
        width: 15%;
        visibility: hidden;
       /* border: 5px solid orange; */
       padding: 1rem;
    }
    .second img{
        height: 120px;
      /* border: 5px solid orange; */
        width: 250px;
    }
    .third{
        //width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 15%;
        padding-right: 1rem;
      /* border: 5px solid orange; */
    }
    .desktop{
        /* border: 1px solid green; */
        display: flex;
        gap: 1rem;
    }
    .mobile{
        /* border: 1px solid blue; */
        display: none;
        visibility: collapse;
    }
    @media screen and (width <= 1400px){
        .topNav{
            padding: 0.5rem;
        }
        .bottomNav{
            display: none;
            padding-block: 0rem;

        }
        .first{
            visibility: visible;
        }
        .second img{
         height: 100px;
         width: 200px;
    }
    .desktop{
        /* border: 1px solid pink; */
        display: none;
        visibility: collapse;
    }
    .mobile{
      display: flex;
      /* border: 1px solid orange; */
      visibility: visible;
      gap: 1rem;
    }
    }
    @media screen and (width <= 600px){
        overflow-x: hidden;
        .second img{
         height: 70px;
         width: 140px;
    }
    }

/* Dropdown button */
.dropbtn {
  background-color: black;
  color: ${({theme}) => theme.colors.grey1};
  padding: 5px;
  //font-size: 16px;
  //border: none;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: ${({theme}) => theme.colors.offWhite};
  min-width: 160px;
  z-index: 2;
  border-radius: 5px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  display: block;
}

.dropdown-content a:hover {
    background-color: ${({theme}) => theme.colors.grey5};
    border-radius: 5px;
}
.dropdown:hover .dropdown-content {display: block;}

`;