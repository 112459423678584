import styled from 'styled-components';

export const EachDiv = styled.div`
    margin-top: 1rem;
    width: 80%;
    height: auto;
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    background-color: ${({theme}) => theme.colors.offWhite};
`;