import {httpActivateUser} from '../../../Hooks/users.hooks';

export const activateUser = async (id, setSuccessState, setIsOpen, setLoading) => {
    setLoading(true);
    try {
        const res = await httpActivateUser(id);
        const data = await res.json();
        if (res.ok) {
            setSuccessState('User has been activated');
        } else {
            setSuccessState(data.msg);
        }
    } catch (error) {
        setSuccessState('An error occurred while activating the user');
        console.error('Error activating user:', error);
    } finally {
        setIsOpen(true);
        setLoading(false);
    }
};