import { EachDiv } from "./each.styles";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../Buttons/secondaryButton.component";

// For Editing a Single Entity
const EachComponent = ({ key, name, route}) => {
    const navigate = useNavigate();

    const editItem = (e) => {
        e.preventDefault();
        navigate(route)
    }
    return ( 
        <EachDiv className="boxShadow" >
            <h3 key={key}>{name}</h3>
            <SecondaryButton functionName={editItem} span="Edit" />
        </EachDiv>
     );
}
 
export default EachComponent;