import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";
import Loader from "../../../Components/Loader/loader.component";
import { useState } from "react";
import { deactivateUser } from "../../../Utils/Users/editUsers/deactivateUser";
import PropTypes from 'prop-types';
// Disbales a user
const DisableUserButton = ({setSuccessState, setIsOpen, id}) => {
    const [loading, setLoading] = useState(false);
    const handleDelete = async(e) => {
        e.preventDefault();
        await deactivateUser(id, setSuccessState, setIsOpen, setLoading);
    }

    return ( 
        <>
        {loading ? <Loader /> :
        <SecondaryButton functionName={handleDelete} span="Disable User" />}
        </>
     );
};

DisableUserButton.propTypes = {
    setSuccessState: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};
 
export default DisableUserButton;