import styled from 'styled-components';

export const MobileCoursesContainer = styled.main`
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    h1{
        margin: 1rem 1rem 0 1rem;
        border-bottom: 2px solid ${({theme}) => theme.colors.orange1};
    }
`;
export const BreadContainer = styled.aside`
    margin-left: 1rem;
`;
