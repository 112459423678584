import { BreadcrumbContainer, EachBreadcrumb } from "./breadcrumbs.styles";
import { useNavigate } from "react-router-dom";

// Breadrumbs
const BreadCrumbs = ({one, oneLink, two, twoLink, three}) => {
    const navigate = useNavigate();

    return ( 
        <BreadcrumbContainer>        
        {one && <EachBreadcrumb>
            <span onClick={() => navigate(oneLink)}>{one}</span>
            <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-cheveron-right-circle">
            <circle cx="12" cy="12" r="10" className="primaryArrow"/>
            <path className="secondaryArrow" d="M10.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z"/></svg>
        </EachBreadcrumb>}
        {(two && !three) && <EachBreadcrumb>
            <span>{two}</span>
        </EachBreadcrumb>}
        {(two && three) && <EachBreadcrumb>
            <span onClick={() => navigate(twoLink)}>{two}</span>
            <svg height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon-cheveron-right-circle">
            <circle cx="12" cy="12" r="10" className="primaryArrow"/>
            <path className="secondaryArrow" d="M10.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z"/></svg>
        </EachBreadcrumb>}
        {three && <EachBreadcrumb>
            <span>{three}</span>
        </EachBreadcrumb>}
        </BreadcrumbContainer>
    );
}
 
export default BreadCrumbs;