import { CourseHomepageContainer, BreadcrumbsContainer, CourseHomeRight,LinksContainer, SubsectionContainer, CouseHomeLeft, PresentationContainer, EachSection } from "../../TakeCourse/CourseHome.styles";
 import { httpGetSingleCourse } from "../../Hooks/courses.hooks";
import { useParams, useNavigate, Link } from "react-router-dom";
import BreadCrumbs from "../../Components/Breadcrumbs/breadcrumbs.component";
import {useQuery} from '@tanstack/react-query';
import { useEffect } from "react";
import Auth from '../../Helpers/auth';

// Page for reviewing the course
const CourseReview = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!Auth.loggedIn()) {
            navigate('/login'); 
        }
    }, [navigate]);
    const {id} = useParams();
    const {data, error, isLoading} = useQuery({
        queryKey: ['userId', id],
        queryFn: () =>  httpGetSingleCourse(id),
        enabled: !!id,
        retry: 5
    })

    if(isLoading){
        return <h3>Loading...</h3>
    }
    if(error){
        return <h3>An error has occured</h3>
    }

    return (  
        <CourseHomepageContainer>
        <CourseHomeRight>
            <LinksContainer>
            <BreadcrumbsContainer>
            <BreadCrumbs one="Home" oneLink='/user' two={data?.courseName}/>
                </BreadcrumbsContainer>
                <Link to='#presentation'>Presentation</Link>
                <Link to='#documentation'>Documentation</Link>
                <Link to='#videos'>Video Links</Link>
                <span className="courseStatus">Course has been completed</span>
                </LinksContainer>
        </CourseHomeRight>
        <CouseHomeLeft>
            {!data ? 
            <>
            <p>{error}</p>
            </>
            :
            <>
            <h1>{data?.courseName}</h1>
            <p>{data?.description}</p>
            <EachSection>
                <SubsectionContainer id="presentation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-presentation"><g><path className="primary" d="M11 18.62l-6.55 3.27a1 1 0 0 1-.9-1.78L11 16.38V5a1 1 0 0 1 2 0v11.38l7.45 3.73a1 1 0 0 1-.9 1.78L13 18.62V21a1 1 0 0 1-2 0v-2.38z"/><path className="secondary" d="M21 14a1 1 0 0 1 0 2H3a1 1 0 0 1 0-2V4a1 1 0 1 1 0-2h18a1 1 0 0 1 0 2v10z"/></g></svg>
                <h2>Presentation</h2>
                </SubsectionContainer>
                {/* <p>Course:{course?.pdf?.publicId}</p> */}
                <PresentationContainer>
                    <iframe src={data?.pdf} title="presentation" width="100%" height="100%" />
                 </PresentationContainer>
            </EachSection>
            <EachSection>
                <SubsectionContainer id="documentation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-attach"><path className="secondary" d="M20.12 11.95l-6.58 6.59a5 5 0 1 1-7.08-7.07l6.59-6.6a3 3 0 0 1 4.24 4.25l-6.58 6.59a1 1 0 1 1-1.42-1.42l6.59-6.58a1 1 0 0 0-1.42-1.42l-6.58 6.59a3 3 0 0 0 4.24 4.24l6.59-6.58a5 5 0 0 0-7.08-7.08l-6.58 6.6a7 7 0 0 0 9.9 9.9l6.59-6.6a1 1 0 0 0-1.42-1.4z"/></svg>
                <h2>Documentation</h2>
                </SubsectionContainer>
                <ul>
                    {/* The as may need to be change to Link */}
                {data?.documentation?.map((l) => {
                    return <li><a href={l} target="_blank" rel="noreferrer" alt={'Documentation link to' + l}>{l}</a></li>
                })}
                </ul>
            </EachSection>
            <EachSection>
                <SubsectionContainer id="videos">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-play"><circle cx="12" cy="12" r="10" className="primary"/><path className="secondary" d="M15.51 11.14a1 1 0 0 1 0 1.72l-5 3A1 1 0 0 1 9 15V9a1 1 0 0 1 1.51-.86l5 3z"/></svg>
                <h2>Videos</h2>
                </SubsectionContainer>
                <ul>
                {data?.videoLinks?.map((l) => {
                    return <li><a href={l} onChange={(e) => e.target} target="_blank" rel="noreferrer" alt={'Video link to '+ l}>{l}</a></li>
                })}
                </ul>
            </EachSection>
            </>
            }
        </CouseHomeLeft>
        </CourseHomepageContainer>
    );
}
 
export default CourseReview;