import { httpGetCompany } from "../../../Hooks/companies.hooks";

export const fetchCompany = async (id, setCompany, setSuccessState) => {
    try {
        const foundCompany = await httpGetCompany(id);
        if (foundCompany) {
            setCompany(foundCompany[0]);
        } else {
            setSuccessState('No company found');
        }
    } catch (error) {
        setSuccessState('An error occurred while fetching the company');
        console.error('Error fetching company:', error);
    }
};